import React from "react";
// ANT DESIGN COMPONENTS
import { Dropdown, MenuProps, Space } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsis,
	faEthernet,
	faGear,
	faPenToSquare,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateAdapter,
	updateOpenModalCreateNewAdapter,
	updateFormCreateEdit,
	updateOpenModalDeleteAdapter,
	updateOpenDrawerAssignActions,
	updateKeysAdapter,
} from "../../../redux/adapters/adapterSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import TableActionsMenuItem from "../../components/table/TableActionsMenuItem";
// INTERFACES
import { AdaptersTableActionsInterface } from "../../../interfaces/Adapter.interface";

function AdaptersTableActions({ selectedItem }: AdaptersTableActionsInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalDelete = () => {
		dispatch(updateAdapter(selectedItem));
		dispatch(updateOpenModalDeleteAdapter(true));
	};
	const handleOpenModalEdit = () => {
		dispatch(updateAdapter(selectedItem));
		dispatch(updateOpenModalCreateNewAdapter(true));
		dispatch(updateFormCreateEdit("edit"));
	};

	const handleOpenDrawerAssignActions = () => {
		dispatch(updateAdapter(selectedItem));
		dispatch(updateOpenDrawerAssignActions(true));
	};

	const handleOpenKeysAdapter = () => {
		dispatch(updateKeysAdapter(selectedItem));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// ROW ACTIONS ************************************ */
	const manageActions = () => {
		const EDIT = validatePermission("adapters-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faPenToSquare}
							text={t("adapter.edit")}
							onClick={handleOpenModalEdit}
						/>
					),
					key: "1",
			  }
			: null;

		const ASSIGN_ACTIONS = validatePermission("adapters-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faGear}
							text={t("general.assignActions")}
							onClick={handleOpenDrawerAssignActions}
						/>
					),
					key: "2",
			  }
			: null;

		const DELETE = validatePermission("adapters-delete", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faTrash}
							text={t("adapter.delete")}
							onClick={handleOpenModalDelete}
						/>
					),
					key: "3",
			  }
			: null;

		const KEYS = validatePermission("adapters-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faEthernet}
							text={t("general.keysAssign")}
							onClick={handleOpenKeysAdapter}
						/>
					),
					key: "0",
			  }
			: null;

		return [EDIT, ASSIGN_ACTIONS, DELETE, KEYS];
	};
	const menu: MenuProps = {
		items: manageActions(),
	};
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Dropdown menu={menu} trigger={["click"]}>
				<Space className='generalStyles__hoverCursor'>
					<FontAwesomeIcon icon={faEllipsis} />
				</Space>
			</Dropdown>
		</div>
	);
}

export default AdaptersTableActions;
