import React from "react";
// ANT DESIGN COMPONENTS
import { useDispatch, useSelector } from "react-redux";
import {
  MapViewDirections,
  MapViewTypes,
} from "../../../interfaces/MapView.interface";
import { RootState } from "../../../app/store";
import { updateMapViewItem } from "../../../redux/mapView/mapViewSlice";
import {
  updateOpenModalControlPanel,
  updateLiftSelected,
  updateLayoutSelected,
  updateModalControlPanelTab,
  updateExistsFaultsInLiftSelected,
  updatePannelFirstState,
  updateExistsFaultsInMonitoringView,
} from "../../../redux/monitoring/monitoringSlice";
import { udpateOpenModalTotpToken } from "../../../redux/authentication/authenticationSlice";
import MonitoringMapViewPin3 from "./MonitoringMapViewPin3";
import {
  BankFloorInterface,
  SocketMessageInterface,
} from "../../../interfaces/EdgeManagement.interface";

function MonitoringMapViewWrapper({
  id,
  direction,
  text,
  x,
  y,
  type,
  lastSocketMessage,
  iconDefinitionElevator,
  iconDefinitionEscalator,
  iconDefinitionMovingwalk,
  layouts,
}: {
  id: string;
  direction: MapViewDirections;
  text: string;
  x: number;
  y: number;
  type: MapViewTypes;
  lastSocketMessage: any;
  iconDefinitionElevator: any;
  iconDefinitionEscalator: any;
  iconDefinitionMovingwalk: any;
  layouts: any;
}) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [lastShortMesage, setLastShortMessage] = React.useState(
    {
      LIFT: "elOutIndicator",
      ESCALATOR: "esStopped",
      MOVINGWALK: "weStopped",
      MAP: "map",
    }[type]
  );
  const [liftSocketMessage, setLiftSocketMessage] = React.useState<any>();
  const [zIndex, setZIndex] = React.useState(0);
  const [serviceMode, setServiceMode] = React.useState("");
  const [hasFaults, setHasFaults] = React.useState(false);
  const [faultMessage, setFaultMessage] = React.useState<boolean>(false);
  const [bankFloors, setBankFloors] = React.useState([]);
  const [isVertical, setIsVertical] = React.useState(false);
  const [currentPositionNameFloor, setCurrentPositionNameFloor] =
    React.useState("");
  const [nextStopNameFloor, setNextStopNameFloor] = React.useState("");
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { mapViewArray } = useSelector((state: any) => state.mapView);
  const { elevatorIconDefinitions } = useSelector(
    (state: RootState) => state.monitoring
  );
  const { totpTokenIsValid } = useSelector(
    (state: RootState) => state.authentication
  );
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const getLayoutAndLift = () => {
    const selectedLayout = layouts.find((layout: any) =>
      layout.lifts.find((lift_: any) => lift_.id === id)
    );
    const selectedLift = selectedLayout.lifts.find(
      (lift_: any) => lift_.id === id
    );
    return { selectedLayout, selectedLift };
  };
  const onClickOpenModalElevator = () => {
    const { selectedLayout, selectedLift } = getLayoutAndLift();
    if (!selectedLayout || !selectedLift) return;
    dispatch(updateLiftSelected(selectedLift));
    dispatch(updateLayoutSelected(selectedLayout));
    dispatch(
      updateModalControlPanelTab({
        liftType: "ELEVATOR",
        tab: hasFaults || faultMessage ? "FAULTS" : "PANEL",
      })
    );
    dispatch(updateExistsFaultsInLiftSelected(hasFaults));
    if (
      // eslint-disable-next-line no-constant-condition
      totpTokenIsValid ||
      process.env.REACT_APP_ENVIRONMENT === "OFFLINE" ||
      true
    ) {
      dispatch(updateOpenModalControlPanel(true));
      dispatch(updatePannelFirstState(lastSocketMessage));
    } else {
      dispatch(udpateOpenModalTotpToken(true));
    }
  };

  const handleClick = () => {
    if (type === MapViewTypes.MAP) {
      // get the selected map view data
      const selectedMapView = mapViewArray.find(
        (mapView: any) => mapView.id === id
      );
      if (selectedMapView) {
        // update my element
        dispatch(updateMapViewItem(selectedMapView));
      }
    } else {
      onClickOpenModalElevator();
    }
  };

  const getDirection: () => "top" | "bottom" | "left" | "right" = () => {
    if (direction === MapViewDirections.UP) return "top";
    if (direction === MapViewDirections.DOWN) return "bottom";
    if (direction === MapViewDirections.LEFT) return "left";
    if (direction === MapViewDirections.RIGHT) return "right";
    return "top";
  };

  const getTypeInLowerCase: () =>
    | "elevator"
    | "escalator"
    | "movingwalk"
    | "map" = () => {
    if (type === MapViewTypes.LIFT) return "elevator";
    if (type === MapViewTypes.ESCALATOR) return "escalator";
    if (type === MapViewTypes.MOVINGWALK) return "movingwalk";
    if (type === MapViewTypes.MAP) return "map";
    return "elevator";
  };
  const getBankFloors = () => {
    layouts.map((item: any) => {
      const foundLiftIndex = item.lifts.findIndex(
        (lift_: any) => lift_.id === id
      );
      if (foundLiftIndex >= 0) {
        setBankFloors(item.bank_floors);
      }
      return true;
    });
  };

  const getFloorName = (socketMessage_: SocketMessageInterface) => {
    bankFloors.map((floor: BankFloorInterface) => {
      if (socketMessage_.position === floor.floor_no) {
        setCurrentPositionNameFloor(floor.name);
      }
      if (
        socketMessage_.car_calls &&
        (socketMessage_.car_calls.front[0] ||
          socketMessage_.car_calls.rear[0]) &&
        (socketMessage_.car_calls.front[0] === floor.floor_no ||
          socketMessage_.car_calls.rear[0] === floor.floor_no)
      ) {
        setNextStopNameFloor(floor.name);
      }
      return true;
    });
  };

  // ************************************************ */
  // USE EFFECT ************************************* */
  React.useEffect(() => {
    if (lastSocketMessage && lastSocketMessage.lift_id === id) {
      setLiftSocketMessage(lastSocketMessage);
    }
    if (
      lastSocketMessage &&
      lastSocketMessage.icon &&
      lastSocketMessage.icon.name &&
      lastSocketMessage.lift_type &&
      lastSocketMessage.lift_id === id &&
      iconDefinitionElevator
    ) {
      if (lastSocketMessage.lift_type === "ELEVATOR") {
        if (
          elevatorIconDefinitions.includes(
            iconDefinitionElevator[lastSocketMessage.icon.name.toLowerCase()]
          )
        ) {
          setLastShortMessage(
            iconDefinitionElevator[lastSocketMessage.icon.name.toLowerCase()]
          );
        }
      } else if (lastSocketMessage.lift_type === "ESCALATOR") {
        setLastShortMessage(
          iconDefinitionEscalator[lastSocketMessage.icon.name.toLowerCase()]
        );
      } else if (lastSocketMessage.lift_type === "MOVINGWALK") {
        setLastShortMessage(
          iconDefinitionMovingwalk[lastSocketMessage.icon.name.toLowerCase()]
        );
      }
    }
    if (
      lastSocketMessage &&
      lastSocketMessage.service_mode &&
      lastSocketMessage.service_mode.name &&
      lastSocketMessage.lift_id === id
    ) {
      setServiceMode(lastSocketMessage.service_mode.name);
    }
  }, [lastSocketMessage]);

  React.useEffect(() => {
    getBankFloors();
  }, []);

  React.useEffect(() => {
    if (liftSocketMessage) {
      getFloorName(liftSocketMessage);
    }
  }, [liftSocketMessage]);

  React.useEffect(() => {
    if (
      direction === MapViewDirections.UP ||
      direction === MapViewDirections.DOWN
    ) {
      setIsVertical(true);
    } else if (
      direction === MapViewDirections.LEFT ||
      direction === MapViewDirections.RIGHT
    ) {
      setIsVertical(false);
    }
  }, [direction]);

  React.useEffect(() => {
    if (!liftSocketMessage) return;
    // FAULT MESSAGE
    if (
      liftSocketMessage.icon.type.toUpperCase() === "FAULT" ||
      liftSocketMessage.icon.type.toUpperCase() === "ERROR"
    ) {
      setFaultMessage(true);
      setHasFaults(true);
      dispatch(updateExistsFaultsInMonitoringView(true));
      setTimeout(() => {
        setFaultMessage(false);
      }, 1000 * 60 * 60);
    }
  }, [liftSocketMessage]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div
      style={{
        position: "absolute",
        top: `${y}px`,
        left: `${x}px`,
        zIndex,
      }}
      onClick={handleClick}
      onMouseEnter={() => setZIndex(99999)}
      onMouseLeave={() => setZIndex(0)}
      role="button"
      tabIndex={0}
      aria-label="map view wrapper"
      onKeyDown={handleClick}
    >
      <MonitoringMapViewPin3
        carName={text}
        nextStop={currentPositionNameFloor !== ""}
        firstStop={currentPositionNameFloor}
        secondStop={nextStopNameFloor}
        icon={lastShortMesage}
        serviceMode={serviceMode}
        direction={getDirection()}
        orientation={isVertical ? "vertical" : "horizontal"}
        fault={hasFaults}
        liftType={getTypeInLowerCase()}
      />
    </div>
  );
}

export default MonitoringMapViewWrapper;
