import React, { useEffect, useState } from "react";
// NAVIGATION
import { useNavigate } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Table, Tag, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import { useLazyGetBanksQuery } from "../../../redux/notifications/notificationAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass, buildFilterString } from "../../../utils/utils";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
// FILTERS
import FilterSearchText from "../../components/filters/FilterSearchText";
// INTERFACES
import { RootState } from "../../../app/store";
import { BankInterface } from "../../../interfaces/Bank.interface";

function NotificationBanksTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const { Column } = Table;
	const navigate = useNavigate();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [banks, setBanks] = useState<BankInterface[]>([]);
	const [total, setTotal] = useState(0);
	const [responseError, setResponseError] = useState<any>();
	const [filterName, setFilterName] = useState("");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetBanks, { data, isLoading, isError, isFetching, error }] =
		useLazyGetBanksQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getData = async (searchAndFilterString: string) => {
		const token = await GETJwtToken();
		triggerGetBanks({
			token,
			searchAndFilterString,
		});
	};

	const onClickCell = (record: BankInterface) => {
		if (record) {
			navigate({
				pathname: "/notification-management/notifications-alerts",
				search: `?idBank=${record.id}`,
			});
		}
	};

	const refreshTable = () => {
		const searchAndFilterString = buildFilterString({
			filterName,
		});
		getData(searchAndFilterString);
	};

	const onClickCleanFilters = () => {
		getData("");
	};

	const generateUUID = () =>
		// eslint-disable-next-line func-names, prefer-arrow-callback
		"xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			// eslint-disable-next-line one-var, no-bitwise
			const r = (Math.random() * 16) | 0,
				// eslint-disable-next-line no-bitwise
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});

	const generateFakeData = () => {
		const users = [
			{
				created_at: "2024-06-20T18:00:16.054373Z",
				updated_at: "2024-06-20T18:00:48.072208Z",
				pref_grid_id: null,
				accepted_terms: null,
				deleted_at: null,
				first_name: "Daniel",
				status: "ACTIVE",
				last_name: "Action",
				middle_name: "Test",
				offline_psw: "xmoB2xDHoKE=",
				email: "daniel.herrera+actiontest@hyperion-solutions.com",
				pref_theme: "",
				cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
				role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
				org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
				id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
				created_offline_psw: true,
			},
			{
				created_at: "2024-07-16T02:08:16.435731Z",
				updated_at: "2024-07-16T02:08:16.435731Z",
				pref_grid_id: null,
				accepted_terms: null,
				deleted_at: null,
				first_name: "test",
				status: "NEW",
				last_name: "pas",
				middle_name: "offline",
				offline_psw:
					"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
				email: "daniel.herrera+encript@hyperion-solutions.com",
				pref_theme: "",
				cognito_id: "00000000-0000-0000-0000-000000000000",
				role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
				org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
				id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
				created_offline_psw: true,
			},
			{
				created_at: "2023-12-18T16:38:35.992509Z",
				updated_at: "2024-04-23T13:39:55.841118Z",
				pref_grid_id: "00000000-0000-0000-0000-000000000000",
				accepted_terms: null,
				deleted_at: null,
				first_name: "Daniel",
				status: "ACTIVE",
				last_name: "Herrrera",
				middle_name: "Diligent",
				offline_psw: "c30da9265ba0",
				email: "daniel.herrera+diligent@hyperion-solutions.com",
				pref_theme: "",
				cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
				role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
				org_id: "907730b5-7960-4dad-abee-124205ae438c",
				id: "0d449764-e18f-4b55-9131-6cd067cd641a",
				created_offline_psw: true,
			},
			{
				created_at: "2024-02-21T23:19:35.504734Z",
				updated_at: "2024-02-21T23:19:35.696362Z",
				pref_grid_id: null,
				accepted_terms: null,
				deleted_at: null,
				first_name: "Ebony",
				status: "ACTIVE",
				last_name: "Johnson",
				offline_psw: "fafe1b60c241",
				email: "ebony.johnson1@pennmedicine.upenn.edu",
				pref_theme: "",
				cognito_id: "7f568050-e987-4f77-aa3c-0443324bc7bc",
				role_id: "df28d37c-863a-42b2-89f5-a9c27a20b2a0",
				org_id: "63db9117-99f0-4224-a95c-6d908a096bd2",
				id: "0d6e18c9-a80b-4ee5-becc-104d1592e3d7",
				created_offline_psw: true,
			},
			{
				created_at: "2023-08-03T20:29:57.705395Z",
				updated_at: "2024-06-24T17:54:35.196863Z",
				pref_grid_id: "1f197fc2-b2ea-4df8-8b9b-6861db64167d",
				accepted_terms: "2024-02-26T16:25:10.719471Z",
				deleted_at: null,
				first_name: "Luis",
				status: "ACTIVE",
				last_name: "Rivera",
				offline_psw: "ihU1gX8RH1Y=",
				email: "lrivera@hyperion-solutions.com",
				pref_theme: "dark",
				cognito_id: "ae7fc64f-6cd1-4f77-be85-eefd3e99d818",
				role_id: "66cd5076-bf0c-454e-b83e-15f35ce0aef2",
				org_id: "131b33d4-a27c-484b-9acd-5f9240f30ae1",
				id: "0f2258e1-d799-4e66-9023-c8c9a72d807a",
				created_offline_psw: true,
			},
		];
		const alerts: any = [];
		banks.map((bank: any, bankIndex: number) => {
			for (let index = 0; index < bankIndex + 5; index += 1) {
				const newAlert: any = {
					id: generateUUID(),
					message: `Fault ${index + 1}`,
					recipients: [],
					bankId: bank.id,
					bankName: bank.name,
				};
				for (
					let index2 = 0;
					index2 < Math.floor(Math.random() * 5) + 1;
					index2 += 1
				) {
					newAlert.recipients.push({
						...users[index2],
						bank: bank.name,
						message: `Fault ${index + 1}`,
					});
				}
				alerts.push(newAlert);
			}
			return true;
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getData("");
	}, []);

	useEffect(() => {
		if (data) {
			if (data && data.data) {
				setBanks(data.data);
			}
			if (data && data.total) {
				setTotal(5);
			} else {
				setTotal(0);
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			setResponseError(error);
		}
	}, [error]);

	useEffect(() => {
		generateFakeData();
	}, [banks]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__mlFix mt-1'>
				<Button
					className='buttonStyle__8'
					icon={
						<FontAwesomeIcon
							icon={faArrowsRotate}
							className='generalStyles__mrFix'
						/>
					}
					onClick={() => {
						onClickCleanFilters();
					}}
				>
					{t("edgeManagement.banks.refreshTable")}
				</Button>
			</div>
			<Table
				locale={{
					emptyText: (
						<TableNoDataBox
							isError={isError}
							errorMessage={
								responseError &&
								responseError.data &&
								responseError.data.message
									? responseError.data.message
									: "Error"
							}
							noItemsFound='No data found'
						/>
					),
				}}
				loading={isLoading || isFetching}
				rowClassName={(record, index) => getTableRowClass(index, theme)}
				dataSource={banks}
				size='small'
				className='mt-3'
				pagination={{
					showSizeChanger: true,
					pageSizeOptions: ["10", "20", "50", "100"],
				}}
				scroll={{ x: 900 }}
				footer={() => (
					<div className='generalStyles__flexEnd'>
						<Tag>{total} Records</Tag>
					</div>
				)}
			>
				{/** NAME */}
				<Column
					onCell={(record: BankInterface) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					title={t("general.name")}
					dataIndex='name'
					key='name'
					filterIcon={<FontAwesomeIcon icon={faSearch} />}
					filterDropdown={() => (
						<FilterSearchText
							placeHolder={t("general.name")}
							filterValue={filterName}
							setFilterValue={setFilterName}
							refreshTable={refreshTable}
							refreshRequireParamenters
						/>
					)}
					sorter={(a: BankInterface, b: BankInterface) =>
						a.name.localeCompare(b.name)
					}
					render={(text: string) => <div className=''>{text}</div>}
				/>
				{/** CHANNEL */}
				<Column
					onCell={(record: BankInterface) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					title={t("general.channel")}
					dataIndex='channel'
					key='channel'
					render={(text, record) => (
						<div className=''>
							{record && record.channel && record.channel.name
								? record.channel.name
								: "--"}
						</div>
					)}
				/>
				{/** SERVER */}
				<Column
					onCell={(record: BankInterface) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					title={t("general.server")}
					dataIndex='server'
					key='server'
					render={(text, record) => (
						<div className=''>
							{record &&
							record.channel &&
							record.channel.server &&
							record.channel.server.name
								? record.channel.server.name
								: "--"}
						</div>
					)}
				/>
				{/** ORGANIZATION */}
				<Column
					onCell={(record: BankInterface) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					title={t("general.organization")}
					dataIndex='organization'
					key='organization'
					render={(text, record) => (
						<div className=''>
							{record &&
							record.channel &&
							record.channel.server &&
							record.channel.server.org &&
							record.channel.server.org.name
								? record.channel.server.org.name
								: "--"}
						</div>
					)}
				/>
				{/** TYPE -- */}
				<Column
					onCell={(record: BankInterface) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					title={t("edgeManagement.lifts.type")}
					dataIndex='type'
					key='type'
					width='100px'
					className=''
					render={(text) => (
						<>
							<div className=''>
								{text === "ELEVATOR" && (
									<Tag color='blue' className='drawer__typeTag'>
										{text}
									</Tag>
								)}
								{text === "ESCALATOR" && (
									<Tag color='volcano' className='drawer__typeTag'>
										{text}
									</Tag>
								)}
								{text === "MOVINGWALK" && (
									<Tag color='green' className='drawer__typeTag'>
										{text}
									</Tag>
								)}
								{text === "BOT" && (
									<Tag color='magenta' className='drawer__typeTag'>
										{text}
									</Tag>
								)}
								{text === "GPIO" && (
									<Tag color='cyan' className='drawer__typeTag'>
										{text}
									</Tag>
								)}
							</div>
						</>
					)}
				/>
				{/** BANK TYPE -- */}
				<Column
					onCell={(record: BankInterface) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					title={t("edgeManagement.banks.configurationType")}
					dataIndex='configurationType'
					key='configurationType'
					width='100px'
					render={(text, record: BankInterface) => (
						<>
							<div className=''>
								{record && record.adapter ? (
									<Tag color='purple' className='drawer__typeTag'>
										ADAPTER
									</Tag>
								) : (
									<Tag color='green' className='drawer__typeTag'>
										CONFIGURATION
									</Tag>
								)}
							</div>
						</>
					)}
				/>
			</Table>
		</div>
	);
}

export default NotificationBanksTable;
