import React, { useEffect, useState } from "react";
// AMPLIFY
import { Auth } from "aws-amplify";
// REACT BOOTSTRAP
import Collapse from "react-bootstrap/Collapse";
// ANT DESIGN COMPONENTS
import { Input, Button } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEnvelope,
	faLock,
	faKey,
	faCircleCheck,
	faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateAmplifyActiveComponent,
	updateUser,
	updateIsLoading,
} from "../../redux/login/loginSlice";

library.add(faEnvelope, faLock, faKey, faCircleCheck, faCircleExclamation);

function CustomAmplifySignIn() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [password, setPassword] = useState("");
	const [loginErrorMessage, setLoginErrorMessage] = useState<any>("");
	const [openCollapseTotpToke, setOpenCollapseTotpToken] = useState(false);
	const [openCollapsePsw, setOpenCollapsePsw] = useState(false);
	const [totpToken, setTotpToken] = useState("");
	const { user } = useSelector((state: any) => state.login);
	
	if( user === null || user === undefined){
		
		Auth.currentAuthenticatedUser()
		.then((data: any) => {
			dispatch(updateUser(data));
		})
	}




	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleChangeCleanForm = () => {
		dispatch(updateIsLoading(false));
		setOpenCollapseTotpToken(false);
		setEmailError(false);
		setLoginErrorMessage("");
		setEmail("");
		setPassword("");
		setTotpToken("");
	};

	const handleLoginWithCredentials = () => {
		dispatch(updateIsLoading(true));
		setOpenCollapsePsw(true);
		dispatch(updateIsLoading(false));
	}
	const handleSubmitSingIn = async () => {
		try {
			dispatch(updateIsLoading(true));
			setLoginErrorMessage("");
			const response = await Auth.signIn(email, password);
			if (response) {
				dispatch(updateUser(response));
				if (response.challengeName && response.challengeName === "MFA_SETUP") {
					setTimeout(() => {
						dispatch(updateAmplifyActiveComponent("mfa-setup"));
						dispatch(updateIsLoading(false));
					}, 1000);
				} else if (
					response.challengeName &&
					response.challengeName === "SOFTWARE_TOKEN_MFA"
				) {
					setOpenCollapseTotpToken(true);
					dispatch(updateIsLoading(false));
				} else if (
					response.challengeName &&
					response.challengeName === "NEW_PASSWORD_REQUIRED"
				) {
					setTimeout(() => {
						dispatch(updateAmplifyActiveComponent("force-change-password"));
						dispatch(updateIsLoading(false));
					}, 1000);
				}
			}
		} catch (error) {
			dispatch(updateIsLoading(false));
			setLoginErrorMessage(error);
		}
	};
	type DomainMap = { [key: string]: string };

	const getDomainFromEmail = (): string | undefined => {
		const atIndex = email.indexOf('@');
		if (atIndex === -1) return undefined; // Handle invalid email
		return email.slice(atIndex + 1);
	  };

	const getIdPName = (domain: string): string | undefined => {
		const domainsEnv = process.env.REACT_APP_COGNITO_IDP_NAME;
	  
		if (!domainsEnv) {
		  return undefined;
		}
	  
		try {
			const domains: DomainMap = JSON.parse(domainsEnv);
		  return domains[domain];
		} catch (error) {
		  return undefined;
		}
	  };

	const handleSubmitSingInSSO = async () => {
		try {
			dispatch(updateIsLoading(true));
			setLoginErrorMessage("");
			const domain = getDomainFromEmail()||"";
			const customProviderName = getIdPName(domain)||"";
			if ( customProviderName === undefined || customProviderName === ""){
				throw new Error("Not a valid email for SSO");
			}else
			{
				await Auth.federatedSignIn({ customProvider: customProviderName}); // redirect  LOGIN  Azure AD
			}
			
		} catch (error) {
			dispatch(updateIsLoading(false));
			setLoginErrorMessage(error);
		}
	};	
	const handleSubmitConfirmSignIn = async () => {
		try {
			dispatch(updateIsLoading(true));
			setLoginErrorMessage("");
			await Auth.confirmSignIn(
				user, // Return object from Auth.signIn()
				totpToken, // Confirmation code
				"SOFTWARE_TOKEN_MFA" // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
			);
			dispatch(updateIsLoading(false));
		} catch (error) {
			dispatch(updateIsLoading(false));
			setLoginErrorMessage(error);
		}
	};

	const handleSubmitSignInEnter = (event: any) => {
		if (event.key === "Enter") {
			handleSubmitSingIn();
		}
	};

	const handleSubmitConfirmSignInEnter = (event: any) => {
		if (event.key === "Enter") {
			handleSubmitConfirmSignIn();
		}
	};

	const validateEmail = (e: string) =>
		String(e)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		const emailValid = validateEmail(email);
		if (emailValid) {
			setEmailError(false);
		} else {
			setEmailError(true);
		}
	}, [email]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div>
				{/* INPUT - EMAIL */}
				<Input
					placeholder='Email'
					status={emailError && email.length > 0 ? "error" : ""}
					prefix={
						<FontAwesomeIcon
							icon={faEnvelope}
							className='generalStyles__info generalStyles__mrFix'
						/>
					}
					onChange={(e) => {
						setEmail(e.target.value);
					}}
					disabled={openCollapseTotpToke}
					value={email}
				/>
				<br />
				<br />
				{/* INPUT - PASSWORD */}
				{/* <Collapse in={openCollapsePsw}> */}
					<Input.Password
						placeholder='Password'
						prefix={
							<FontAwesomeIcon
								className='generalStyles__info generalStyles__mrFix'
								icon={faLock}
							/>
						}
						onChange={(e) => {
							setPassword(e.target.value);
						}}
						onKeyDown={handleSubmitSignInEnter}
						disabled={emailError }
						hidden={!openCollapsePsw}
						value={password}
					/>
				{/* </Collapse> */}
				{/* BUTTON - PASSWORD */}
				<Collapse in={openCollapseTotpToke}>
					<div className='mt-4 mb-4'>
						{/* INPUT - TOTP TOKEN */}
						<Input
							placeholder='Token'
							prefix={
								<FontAwesomeIcon
									className='generalStyles__info generalStyles__mrFix'
									icon={faKey}
								/>
							}
							onChange={(e) => {
								setTotpToken(e.target.value);
							}}
							onKeyDown={handleSubmitConfirmSignInEnter}
							value={totpToken}
						/>
					</div>
				</Collapse>

				{/** BUTTON - FORGOT PASSWORD */}
				{!openCollapseTotpToke && openCollapsePsw ? (
					<div
						role='button'
						tabIndex={0}
						onKeyDown={() => {
							dispatch(updateAmplifyActiveComponent("forgot-password"));
						}}
						onClick={() => {
							dispatch(updateAmplifyActiveComponent("forgot-password"));
						}}
						className='loginStyles__forgotPasswordButton'
					>
						<div>
							<span>Forgot-Password?</span>
						</div>
					</div>
				) : (
					<></>
				)}
				{/** BUTTON - LOGIN */}
				<div className='generalStyles__flex'>
					<div>
						{!openCollapseTotpToke && openCollapsePsw && (
								<Button
									className='buttonStyle__1'
									disabled={
										email.length === 0 || password.length === 0 || emailError
									}
									onClick={handleSubmitSingIn}
								>
									Login
								</Button>
							)}
						{openCollapseTotpToke && openCollapsePsw &&  (
							<Button
								className='buttonStyle__1'
								disabled={totpToken.length === 0}
								onClick={handleSubmitConfirmSignIn}
							>
								Login
							</Button>
						)}

						 <br />
						 <br />
						{ !openCollapsePsw &&  
							<Button
								className='buttonStyle__1'	
								onClick={handleLoginWithCredentials}
								disabled={email.length === 0 || emailError}

							>
								Login with credentials
							
							</Button>
						}
						<br />
						<br />
						
						{ !openCollapseTotpToke && !openCollapsePsw && (
									<Button 
									className='buttonStyle__1'
									onClick={handleSubmitSingInSSO}
									disabled={email.length === 0 || emailError}
								
								>AD Login</Button>
											)}
					</div>
					<div className='generalStyles__mlFix'>
						{openCollapseTotpToke ? (
							<Button
								disabled={
									email.length === 0 || password.length === 0 || emailError
								}
								className={
									email.length === 0 || password.length === 0 || emailError
										? "button__inactive__1 loginStyles__buttonInactive"
										: "buttonStyle__1 loginStyles__buttonActive"
								}
								onClick={handleChangeCleanForm}
							>
								<span>Sign in With Another Account</span>
							</Button>
						) : (
							<></>
						)}
					</div>
				</div>
			</div>

			<p className='loginStyle__errorMessage'>{loginErrorMessage.message}</p>
		</div>
	);
}

export default CustomAmplifySignIn;
