/* eslint-disable */
import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Button, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// TIMEZONE
import timezones from "timezones-list";
import moment from "moment-timezone";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFilter,
	faInfoCircle,
	faDownload,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateOpenDrawerFilters,
	updateFilters,
	updateOpenAlertDownloadReport,
	updateChartReportTheme,
} from "../../redux/reports/reportsSlice";
// COMPONENTS
import ReportsFilterDateRange from "./filters/ReportsFilterDateRange";
import ReportsFilterDateType from "./filters/ReportsFilterDateType";
import ReportsFilterLevelType from "./filters/ReportsFilterLevelType";
import ReportsFilterGetBanks from "./filters/ReportsFilterGetBanks";
import ReportsFilterGetServers from "./filters/ReportsFilterGetServers";
import ReportsFilterGetOrganizations from "./filters/ReportsFilterGetOrganizations";
import ReportsFilterGetLifts from "./filters/ReportsFilterGetLifts";
import ReportsFilterDateDay from "./filters/ReportsFilterDateDay";
import ReportsFilterDateWeek from "./filters/ReportsFilterDateWeek";
import ReportsFilterDateMonth from "./filters/ReportsFilterDateMonth";
import ReportsFilterDateQuarter from "./filters/ReportsFilterDateQuarter";
import ReportsFilterDateYear from "./filters/ReportsFilterDateYear";
import ReportsFilterWeeksNumber from "./filters/ReportsFilterWeeksNumber";
import ReportsFilterLimitNumber from "./filters/ReportsFilterLimitNumber";
import ReportsPDFViewerModal from "./ReportsPDFViewerModal";
import ReportsDrawerFilters from "./drawer/ReportsDrawerFilters";
// INTERFACES
import { RootState } from "../../app/store";
import {
	TimeZoneInterface,
	ReportsHeaderInterface,
} from "../../interfaces/Reports.interface";

function ReportsHeader({ reportDefaultData }: ReportsHeaderInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, idServerPositionCero, idBankPositionCero } = useSelector(
		(state: RootState) => state.report
	);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getDefaultTimezone = () => {
		// Find default timezone
		const index = timezones.findIndex(
			(object: TimeZoneInterface) => object.tzCode === moment.tz.guess()
		);
		if (index >= 0) {
			return {
				timezoneName: timezones[index].name,
				timezoneLabel: timezones[index].label,
				timezoneCode: timezones[index].tzCode,
				timezoneUTC: timezones[index].utc,
			};
		}
		return {
			timezoneName: "",
			timezoneLabel: "",
			timezoneCode: "",
			timezoneUTC: "",
		};
	};

	const onClickResetFilters = () => {
		const defaultTime = getDefaultTimezone();
		// Format the current date to the specified format
		dispatch(
			updateFilters({
				reportType: reportDefaultData.reportType,
				levelTypeName: reportDefaultData.levelTypeName,
				displaySelectTypeLevel: reportDefaultData.displaySelectTypeLevel,
				liftType: reportDefaultData.liftType,
				startDate: moment().startOf("month").toISOString(),
				endDate: moment().endOf("month").toISOString(),
				dateType: reportDefaultData.dateType,
				organizationId: "",
				organizationName: "",
				serverName: "",
				serverId: idServerPositionCero,
				liftName: "",
				liftId: "",
				bankName: "",
				bankId: idBankPositionCero,
				banks: [],
				displayAdvanceFiltersExclusions:
					reportDefaultData.displayAdvanceFiltersExclusions,
				exclusionTimeList: [],
				exclusionDateList: [],
				exclusionTime: false,
				exclusionDate: false,
				exclusionSaturdays: false,
				exclusionSundays: false,
				exclusionHolidays: false,
				timezoneCode: defaultTime?.timezoneCode,
				timezoneLabel: defaultTime?.timezoneLabel,
				timezoneUTC: defaultTime?.timezoneUTC,
				timezoneName: defaultTime?.timezoneName,
				timezoneLocal: false,
				tableListColumns: [],
				tableSelectedColumns: [],
				useTablePagination: reportDefaultData.useTablePagination,
				defaultColumns: reportDefaultData.defaultColumns,
				displayAdvanceFiltersOffsets:
					reportDefaultData.displayAdvanceFiltersOffsets,
				disabledOffsets: reportDefaultData.disabledOffsets,
				defaultOffsets: reportDefaultData.defaultOffsets,
				offsetsList: reportDefaultData.defaultOffsets,
				infoDescriptionText: reportDefaultData.infoDescriptionText,
				reportTitle: reportDefaultData.reportTitle,
				displayDownloadCSVReport: reportDefaultData.displayDownloadCSVReport,
				displayDownloadPDFReport: reportDefaultData.displayDownloadPDFReport,
				displayRecentWeeksPicket: reportDefaultData.displayRecentWeeksPicket,
				displayInputLimitItems: reportDefaultData.displayInputLimitItems,
				weeksNumber: 5,
				limitNumber: 10,
				sortAsc: true,
				getFirstDefaultValue: true,
				carsList: [],
				selectedCarsList: [],
				chartType: reportDefaultData.chartType,
				displayAdvanceFilterCharts:
					reportDefaultData.displayAdvanceFilterCharts,
				displayAdvanceFiltersTable:
					reportDefaultData.displayAdvanceFiltersTable,
				displayDateByRange: reportDefaultData.displayDateByRange,
				displayMinimumActivation: reportDefaultData.displayMinimumActivation,
				displayDate: reportDefaultData.displayDate,
				displaySelectLiftType: reportDefaultData.displaySelectLiftType,
				dateTypeOptions: reportDefaultData.dateTypeOptions,
				dateDaysLimit: reportDefaultData.dateDaysLimit,
				dateWeeksLimit: reportDefaultData.dateWeeksLimit,
				dateMonthsLimit: reportDefaultData.dateMonthsLimit,
				dateQuarterLimit: reportDefaultData.dateQuarterLimit,
				dateYearsLimit: reportDefaultData.dateYearsLimit,
				dateTimeSeconds: 0,
				displayChartMirror: reportDefaultData.displayChartMirror,
				chartMirror: reportDefaultData.chartMirror,
				urlChartImage: "",
			})
		);
	};

	const onClickDownloadCSVAndPDF = () => {
		dispatch(updateOpenAlertDownloadReport(true));
	};

	const disabledSearchRerport = () => {
		let disabled = false;
		if (
			(filters.levelTypeName === "ORGANIZATIONS" &&
				filters.organizationId === "") ||
			(filters.levelTypeName === "SERVERS" && filters.serverId === "") ||
			(filters.levelTypeName === "BANKS" && filters.bankId === "") ||
			(filters.levelTypeName === "LIFTS" && filters.liftId === "")
		) {
			disabled = true;
		}
		return disabled;
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		onClickResetFilters();
	}, []);

	useEffect(() => {
		dispatch(updateChartReportTheme(theme));
	}, [theme]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__spaceBetween' style={{ flexWrap: "wrap" }}>
			<div className='generalStyles__flex mt-1' style={{ flexWrap: "wrap" }}>
				{/**  DATE */}
				{filters.displayDate && (
					<>
						{filters.displayDateByRange ? (
							<>
								<div className='generalStyles__mrFix generalStyles__width300px'>
									<ReportsFilterDateRange />
								</div>
							</>
						) : (
							<div className='generalStyles__mrFix generalStyles__width300px'>
								{filters.dateType === "TODAY" && <ReportsFilterDateDay />}
								{filters.dateType === "WEEK" && <ReportsFilterDateWeek />}
								{filters.dateType === "MONTH" && <ReportsFilterDateMonth />}
								{filters.dateType === "QUARTER" && <ReportsFilterDateQuarter />}
								{filters.dateType === "YEAR" && <ReportsFilterDateYear />}
							</div>
						)}
						{/**  DATE TYPE */}
						<div className='generalStyles__mrFix generalStyles__width100px'>
							<ReportsFilterDateType />
						</div>
					</>
				)}
				{/** LIMIT ITEMS */}
				{filters.displayInputLimitItems && (
					<div className='generalStyles__width150px generalStyles__mrFix'>
						<ReportsFilterLimitNumber />
					</div>
				)}
				{/**  WEEKS PICKER */}
				{filters.displayRecentWeeksPicket && (
					<div className='generalStyles__width150px generalStyles__mrFix'>
						<ReportsFilterWeeksNumber />
					</div>
				)}
				{/** CASCADER LEVEL TYPE */}
				{filters.displaySelectTypeLevel && (
					<div className='generalStyles__mrFix generalStyles__width200px'>
						<ReportsFilterLevelType />
					</div>
				)}
				{/** FILTER BY CASCADER */}
				<div className='generalStyles__mrFix generalStyles__width200px'>
					<div>
						{filters.levelTypeName === "SERVERS" && <ReportsFilterGetServers />}
						{filters.levelTypeName === "BANKS" && <ReportsFilterGetBanks />}
						{filters.levelTypeName === "ORGANIZATIONS" && (
							<ReportsFilterGetOrganizations />
						)}
						{filters.levelTypeName === "LIFTS" && <ReportsFilterGetLifts />}
					</div>
				</div>
				{/** REPORT TYPE INFORMATION */}
				<div>
					<Tooltip title={t(`${filters.infoDescriptionText}`)}>
						<FontAwesomeIcon
							icon={faInfoCircle}
							fontSize={22}
							className='generalStyles__info'
						/>
					</Tooltip>
				</div>
			</div>
			<div className='generalStyles__flex'>
				{/** DOWNLOAD CSV/EXCEL/ PDF */}
				{(filters.displayDownloadCSVReport ||
					filters.displayDownloadPDFReport) && (
					<div className='generalStyles__mlFix'>
						<Tooltip
							placement='topLeft'
							title={`${t("general.download")} ${t("general.report")}`}
						>
							<Button
								className={
									disabledSearchRerport()
										? "buttonStyle__inactive__1__dark"
										: "buttonStyle__8"
								}
								icon={<FontAwesomeIcon icon={faDownload} />}
								onClick={onClickDownloadCSVAndPDF}
								disabled={disabledSearchRerport()}
							/>
						</Tooltip>
					</div>
				)}
				{/** OPEN ADVANCE FILTERS DRAWER */}
				<div className='generalStyles__mlFix'>
					<Tooltip placement='topLeft' title={t("general.advanceFilters")}>
						<Button
							className='buttonStyle__6'
							icon={<FontAwesomeIcon icon={faFilter} />}
							onClick={() => {
								dispatch(updateOpenDrawerFilters(true));
							}}
						/>
					</Tooltip>
				</div>
			</div>
			{/** COMPONENTES */}
			<ReportsDrawerFilters />
			<ReportsPDFViewerModal />
		</div>
	);
}

export default ReportsHeader;
