import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import MapViewTable from "./MapViewTable";
import MapViewDeleteModal from "./MapViewDeleteModal";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";
import "./styles.css";

// eslint-disable-next-line arrow-body-style
const MapViewPage = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const { permissions } = useSelector((state: any) => state.user);
  const { theme } = useSelector((state: RootState) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {validatePermission("permission-not-defined", permissions) ? (
        <div className={`layout__contentPage__${theme}`}>
          <MapViewTable />
          <MapViewDeleteModal />
        </div>
      ) : (
        <UnauthorizedPage />
      )}
    </div>
  );
};

export default MapViewPage;
