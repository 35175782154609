import React from "react";
import { DownOutlined } from "@ant-design/icons";
// eslint-disable-next-line no-unused-vars
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetMapViewQuery } from "../../../redux/mapView/mapViewAPI";
import GETJwtToken from "../../../redux/authentication/authentication";
import { updateMapViewItem } from "../../../redux/mapView/mapViewSlice";

function MonitoringMapViewList() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [
    triggerGetMapView,
    { data: dataGetMapView, isLoading: isLoadingGetMapView },
  ] = useLazyGetMapViewQuery();

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { selectedOrganization, mapViewItem } = useSelector(
    (state: any) => state.mapView
  );

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const getInitialData = async () => {
    if (!selectedOrganization) {
      return;
    }
    const token = await GETJwtToken();
    const BODY: any = {
      org_id: selectedOrganization,
    };
    triggerGetMapView({
      token,
      body: BODY,
    });
  };

  // ************************************************ */
  // USE EFFECT ************************************* */
  React.useEffect(() => {
    getInitialData();
  }, [selectedOrganization]);

  // ************************************************ */
  // COMPONENT ************************************** */

  const items: MenuProps["items"] = dataGetMapView?.data.map((item: any) => ({
    key: item.id,
    label: item.name,
    onClick: () => {
      dispatch(updateMapViewItem(item));
    },
  }));

  if (isLoadingGetMapView) return <div>Loading...</div>;

  return (
    <Dropdown menu={{ items }}>
      <Space
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        {mapViewItem?.name || "Select a map"}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
}

export default MonitoringMapViewList;
