import React from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Checkbox } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { faUser } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerAssignUser } from "../../../redux/notifications/notificationSlice";
// COMPONENTS
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
import DrawerCloseSaveButton from "../../components/drawer/DrawerCloseSaveButton";
import DrawerRowItem from "../../components/drawer/DrawerRowItem";
// INTERFACES
import { RootState } from "../../../app/store";

function NotificationAlertsDrawerAssignUser() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerAssignUser } = useSelector(
		(state: RootState) => state.notification
	);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerAssignUser(false));
	};

	const onClickSave = () => {
		dispatch(updateOpenDrawerAssignUser(false));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='550'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerAssignUser}
		>
			<div>
				<DrawerRowDivider icon={faUser} title={t("general.alert")} />
			</div>
			<div className={`drawer__box__${theme}`}>
				<DrawerRowItem
					title='ID'
					value='a4a8s5s78s-ad5fadsf-823jkf8923-adsfasd'
				/>
				<DrawerRowItem title={t("general.message")} value='Fault 1' />
				<DrawerRowItem title={t("general.bank")} value='West Loch ES1' />
				<DrawerRowItem title={t("general.organization")} value='West Loch' />
			</div>
			<br />
			<div>
				<DrawerRowDivider icon={faUser} title={t("general.assignUser")} />
			</div>
			<div className={`drawer__box__${theme}`}>
				{[
					{
						id: 1,
						name: "Errick Sabbins",
					},
					{
						id: 2,
						name: "Brenda MacElroy",
					},
					{
						id: 3,
						name: "Barney Romney",
					},
					{
						id: 4,
						name: "Shaun Jirick",
					},
					{
						id: 5,
						name: "Monti Babb",
					},
					{
						id: 6,
						name: "Irwinn Haydn",
					},
					{
						id: 7,
						name: "Goldi Oxberry",
					},
					{
						id: 8,
						name: "Meghan Kiley",
					},
					{
						id: 9,
						name: "Bethany Arlott",
					},
					{
						id: 10,
						name: "Dinny Macak",
					},
					{
						id: 11,
						name: "Cybil Crummy",
					},
					{
						id: 12,
						name: "Diena Culshew",
					},
					{
						id: 13,
						name: "Abran Dobbin",
					},
					{
						id: 14,
						name: "Lona Mowsdell",
					},
					{
						id: 15,
						name: "Nadia Selcraig",
					},
					{
						id: 16,
						name: "Ethelbert Throssell",
					},
					{
						id: 17,
						name: "Kendal Silby",
					},
					{
						id: 18,
						name: "Jeni Pelchat",
					},
					{
						id: 19,
						name: "Ottilie Antos",
					},
					{
						id: 20,
						name: "Gabriella Chadwick",
					},
					{
						id: 21,
						name: "Jonathan Hibbart",
					},
					{
						id: 22,
						name: "Eberhard Grigorkin",
					},
				].map((user: any) => (
					<div className='generalStyles__flex mt-2' key={user.id}>
						<div className='generalStyles__width50px'>
							<Checkbox />
						</div>
						<div>{user.name}</div>
					</div>
				))}
			</div>
			{/** ---------------------------------------------------- */}
			{/** COMPONENT CLOSE SAVE BUTTON */}
			<DrawerCloseSaveButton
				onClickClose={onClickClose}
				onClickSave={onClickSave}
				disableSaveButton={false}
			/>
			<br />
		</Drawer>
	);
}

export default NotificationAlertsDrawerAssignUser;
