import React, { useEffect, useState } from "react";
// NAVIGATION
import { useLocation } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Table, Tag, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	useLazyGetSchedulesQuery,
	notificationAPI,
} from "../../../redux/notifications/notificationAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
import NotificationSchedulesTableActions from "./NotificationSchedulesTableActions";
// INTERFACES
import { RootState } from "../../../app/store";

function NotificationSchedulesTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column } = Table;
	const location = useLocation();
	const idBank = new URLSearchParams(location.search).get("idBank");
	const idAlert = new URLSearchParams(location.search).get("idAlert");
	const idUser = new URLSearchParams(location.search).get("idUser");
	const fakeData = [
		{
			id: "a2a1s-a1sdfaf2-adfasdf54-5a8a5a",
			message: "Fault 1",
			user: "daniel.herrera+actiontest@hyperion-solutions.com",
			alert: "SMS",
			day: "Monday",
			time: "11:00am To 11:30am",
		},
		{
			id: "a2a1s-a1sdfaf2-adfasdf54-2s3s6s",
			message: "Fault 1",
			user: "daniel.herrera+actiontest@hyperion-solutions.com",
			alert: "Email",
			day: "Friday",
			time: "11:00am To 11:30am",
		},
		{
			id: "a2a1s-a1sdfaf2-adfasdf54-7d4d7d",
			message: "Fault 1",
			user: "daniel.herrera+actiontest@hyperion-solutions.com",
			alert: "Whatsapp",
			day: "Saturday",
			time: "11:00am To 11:30am",
		},
	];

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [schedules, setSchedules] = useState<any>([]);
	const [total, setTotal] = useState(0);
	const [responseError, setResponseError] = useState<any>();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetSchedules, { data, isLoading, isError, isFetching, error }] =
		useLazyGetSchedulesQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleClearData = () => {
		dispatch(notificationAPI.util.resetApiState());
	};

	const getData = async () => {
		const token = await GETJwtToken();
		handleClearData();
		triggerGetSchedules({
			token,
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getData();
	}, [idBank, idAlert, idUser]);

	useEffect(() => {
		if (data) {
			if (data && data.data) {
				setSchedules(fakeData);
				setTotal(fakeData.length);
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			setResponseError(error);
		}
	}, [error]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__mlFix mt-1'>
				<Button
					className='buttonStyle__8'
					icon={
						<FontAwesomeIcon
							icon={faArrowsRotate}
							className='generalStyles__mrFix'
						/>
					}
					onClick={() => {
						getData();
					}}
				>
					{t("general.refreshTable")}
				</Button>
			</div>
			<Table
				locale={{
					emptyText: (
						<TableNoDataBox
							isError={isError}
							errorMessage={
								responseError &&
								responseError.data &&
								responseError.data.message
									? responseError.data.message
									: "Error"
							}
							noItemsFound='No data found'
						/>
					),
				}}
				loading={isLoading || isFetching}
				rowClassName={(record, index) => getTableRowClass(index, theme)}
				dataSource={schedules}
				size='small'
				className='mt-3'
				pagination={{
					showSizeChanger: true,
					pageSizeOptions: ["10", "20", "50", "100"],
				}}
				scroll={{ x: 900 }}
				footer={() => (
					<div className='generalStyles__flexEnd'>
						<Tag>{total} Records</Tag>
					</div>
				)}
			>
				{/** USER */}
				<Column
					title={t("general.user")}
					dataIndex='user'
					key='user'
					render={(text: string) => <div className=''>{text}</div>}
				/>
				{/** MESSAGE */}
				<Column
					title={t("general.message")}
					dataIndex='message'
					key='message'
					render={(text: string) => <div className=''>{text}</div>}
				/>
				{/** ALERT */}
				<Column
					title={t("general.alert")}
					dataIndex='alert'
					key='alert'
					render={(text: string) => <div className=''>{text}</div>}
				/>
				{/** DAY */}
				<Column
					title={t("general.day")}
					dataIndex='day'
					key='day'
					render={(text: string) => <div className=''>{text}</div>}
				/>
				{/** TIME */}
				<Column
					title={t("general.time")}
					dataIndex='time'
					key='time'
					render={(text: string) => <div className=''>{text}</div>}
				/>
				{/** ACTIONS */}
				<Column
					title={t("adapter.actions")}
					key='action'
					width='100px'
					render={(_: any, record: any) => (
						<NotificationSchedulesTableActions selectedItem={record} />
					)}
				/>
			</Table>
		</div>
	);
}

export default NotificationSchedulesTable;
