import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Button, Modal, Tabs } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOpenModalControlPanel,
	updateLiftSelected,
	updateLayoutSelected,
} from "../../../redux/monitoring/monitoringSlice";
// COMPONENTS
import ControlPanelHeader from "./ControlPanelHeader";
import ControlPanelElevator from "./ControlPanelElevator";
import ControlPanelHallCall from "./ControlPanelHallCall";
import ControlPanelFaults from "./ControlPanelFaults";
import ControlPanelActions from "./ControlPanelActions";
import ControlPanelStatus from "./ControlPanelStatus";
// INTERFACES
import {
	MonitoringModalSettingsInterface,
	SocketMessageInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function ControlPanel({
	socketMessage,
	iconDefinitionElevator,
	iconDefinitionEscalator,
	iconDefinitionMovingwalk,
}: MonitoringModalSettingsInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const VALID_MESSAGES_NO_ICON = ["PF", "FAULT"];
	const displayStatus = process.env.REACT_APP_ENVIRONMENT !== "PRODUCTION";
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		car_calls: { front: [], rear: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
			},
			down: {
				front: [],
				rear: [],
			},
			up_assigned: { front: [], rear: [] },
			down_assigned: { front: [], rear: [] },
		},
		faults: false,
		position: 0,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [tabActivekey, setTabActiveKey] = useState<string>("elevator");
	const [elevatorSocketMessage, setElevatorSocketMessage] =
		useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);
	const [escalatorSocketMessage, setEscalatorSocketMessage] =
		useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);
	const [movingWalkSocketMessage, setMovingWalkSocketMessage] =
		useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const {
		liftSelected,
		layoutSelected,
		openModalControlPanel,
		controlPanelTab,
	} = useSelector((state: RootState) => state.monitoring);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickCancel = () => {
		dispatch(updateOpenModalControlPanel(false));
		dispatch(
			updateLiftSelected({
				id: "",
				bank_id: "",
				control_panel: {},
				direction: "",
				name: "",
				position: 0,
				type: "",
				lift_floors: [],
			})
		);
		dispatch(
			updateLayoutSelected({
				value: "",
				label: "",
				children: [],
				type: "",
				idOrganization: "",
				idServer: "",
				idChannel: "",
				idBank: "",
				nameOrganization: "",
				nameServer: "",
				nameChannel: "",
				typeChannel: "",
				id: "",
				active: false,
				board: 0,
				columns: 0,
				floor_below_lobby: 0,
				floor_count: 0,
				logical_port: 0,
				name: "",
				number_of_lifts: 0,
				channel_id: "",
				rear_doors: false,
				stops: 0,
				control_panel: {},
				bank_floors: [],
				lifts: [],
				floorsInUse: [],
				i: "",
				x: 0,
				y: 0,
				w: 0,
				h: 0,
				l: 0,
				f: 0,
				floor: 0,
			})
		);
	};

	const checkIfSocketMessageIsValid = (obj: SocketMessageInterface) =>
		obj &&
		obj.bank_id &&
		obj.bank_id !== "" &&
		obj.lift_id &&
		obj.lift_id !== "" &&
		obj.lift_type &&
		obj.lift_type !== "" &&
		obj.car_calls &&
		obj.car_calls.front &&
		obj.car_calls.rear &&
		obj.hall_calls &&
		obj.hall_calls.up &&
		obj.hall_calls.up.front &&
		obj.hall_calls.up.rear &&
		obj.hall_calls.down &&
		obj.hall_calls.down.front &&
		obj.hall_calls.down.rear &&
		obj.hall_calls.up_assigned &&
		obj.hall_calls.up_assigned.front &&
		obj.hall_calls.up_assigned.rear &&
		obj.hall_calls.down_assigned &&
		obj.hall_calls.down_assigned.front &&
		obj.hall_calls.down_assigned.rear &&
		obj.position >= 0 &&
		obj.icon &&
		obj.icon.type &&
		obj.icon.name &&
		obj.service_mode;
	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		if (
			socketMessage &&
			socketMessage.lift_id &&
			liftSelected &&
			liftSelected.id &&
			liftSelected.id === socketMessage.lift_id
		) {
			if (
				(socketMessage.lift_type === "ELEVATOR" &&
					checkIfSocketMessageIsValid(socketMessage)) ||
				socketMessage.lift_type === "ESCALATOR" ||
				socketMessage.lift_type === "MOVINGWALK"
			) {
				if (
					socketMessage.lift_type === "ELEVATOR" &&
					(iconDefinitionElevator[socketMessage.icon.name.toLowerCase()] ||
						VALID_MESSAGES_NO_ICON.includes(
							socketMessage.icon.name.replace(/[0-9]/g, "").toUpperCase()
						) ||
						socketMessage.icon.type.toUpperCase() === "FAULT" ||
						socketMessage.icon.type.toUpperCase() === "CARSERVICE")
				) {
					setElevatorSocketMessage(socketMessage);
				} else if (
					socketMessage.lift_type === "ESCALATOR" &&
					(iconDefinitionEscalator[socketMessage.icon.name.toLowerCase()] ||
						VALID_MESSAGES_NO_ICON.includes(
							socketMessage.icon.name.replace(/[0-9]/g, "").toUpperCase()
						) ||
						socketMessage.icon.type.toUpperCase() === "FAULT" ||
						socketMessage.icon.type.toUpperCase() === "CARSERVICE")
				) {
					setEscalatorSocketMessage(socketMessage);
				} else if (
					socketMessage.lift_type === "MOVINGWALK" &&
					(iconDefinitionMovingwalk[socketMessage.icon.name.toLowerCase()] ||
						VALID_MESSAGES_NO_ICON.includes(
							socketMessage.icon.name.replace(/[0-9]/g, "").toUpperCase()
						) ||
						socketMessage.icon.type.toUpperCase() === "FAULT" ||
						socketMessage.icon.type.toUpperCase() === "CARSERVICE")
				) {
					setMovingWalkSocketMessage(socketMessage);
				}
			}
		}
	}, [socketMessage]);

	useEffect(() => {
		setTabActiveKey(controlPanelTab.tab);
	}, [controlPanelTab]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			closable={false}
			onCancel={onClickCancel}
			open={openModalControlPanel}
			footer={null}
			width={1000}
			maskStyle={{ backdropFilter: "blur(2px)" }}
		>
			<div>
				{layoutSelected &&
					liftSelected &&
					layoutSelected.bank_floors &&
					openModalControlPanel && (
						<>
							{/** ************************************************** */}
							{/* HEADER */}
							<div>
								<ControlPanelHeader
									lift={liftSelected}
									layout={layoutSelected}
									iconDefinitionElevator={iconDefinitionElevator}
									iconDefinitionEscalator={iconDefinitionEscalator}
									iconDefinitionMovingwalk={iconDefinitionMovingwalk}
									socketMessageElevator={elevatorSocketMessage}
									socketMessageEscalator={escalatorSocketMessage}
									socketMessageMovingwalk={movingWalkSocketMessage}
								/>
							</div>
							<div>
								{/** ************************************************** */}
								{/* BODY */}
								<Tabs
									onTabClick={(key: string) => {
										setTabActiveKey(key);
									}}
									activeKey={tabActivekey}
								>
									{controlPanelTab.liftType === "ELEVATOR" && (
										<>
											<Tabs.TabPane
												key='PANEL'
												tab='Car Calls'
												disabled={liftSelected.id === ""}
											>
												<ControlPanelElevator
													currentFloor={elevatorSocketMessage.position}
													carService={elevatorSocketMessage.service_mode.name}
												/>
											</Tabs.TabPane>
											<Tabs.TabPane key='HALLCALLS' tab='Hall Calls'>
												<ControlPanelHallCall
													currentFloor={elevatorSocketMessage.position}
												/>
											</Tabs.TabPane>
											<Tabs.TabPane key='ACTIONS' tab='Actions'>
												<ControlPanelActions
													carService={elevatorSocketMessage.service_mode.name}
													liftId={
														liftSelected && liftSelected.id
															? liftSelected.id
															: ""
													}
													actions={
														layoutSelected && layoutSelected.actions
															? layoutSelected.actions
															: null
													}
													serverId={
														layoutSelected && layoutSelected.idServer
															? layoutSelected.idServer
															: ""
													}
													isControlPanel
												/>
											</Tabs.TabPane>
										</>
									)}
									<Tabs.TabPane key='FAULTS' tab='Faults'>
										<ControlPanelFaults />
									</Tabs.TabPane>
									{displayStatus && controlPanelTab.liftType === "ELEVATOR" && (
										<Tabs.TabPane key='STATUS' tab='Status'>
											<ControlPanelStatus
												layout={layoutSelected}
												socketMessage={elevatorSocketMessage}
											/>
										</Tabs.TabPane>
									)}
								</Tabs>
								{/** ************************************************** */}
								{/* CLOSE AND SAVE BUTTON */}
								<div className='generalStyles__flexEnd mt-4'>
									<div>
										<Button onClick={onClickCancel} className='buttonStyle__4'>
											{t("general.close")}
										</Button>
									</div>
								</div>
							</div>
						</>
					)}
			</div>
		</Modal>
	);
}

export default ControlPanel;
