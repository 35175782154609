import React, { useEffect, useState, useRef } from "react";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// HTML CANVAS
import html2canvas from "html2canvas";
// MOMENT DATE FORMAT
import moment from "moment";
// MUI CHARTS
import { BarChart, BarChartProps } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { HighlightItemData } from "@mui/x-charts/context";
import Box from "@mui/material/Box";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLong, faUpLong } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateFilters,
	updateBlockFilters,
} from "../../../redux/reports/reportsSlice";
// CSS CUSTOM STYLES
import {
	getChartBackgroundColor,
	getChartTextColor,
	getChartTicklineColor,
	generateBarsDataFault,
	getChartAxisHighlight,
	getAxisLabels,
} from "../utils";
// COMPONENTS
import ReportsAlertDownloadModal from "../ReportsAlertDownloadModal";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ChartBar({
	data,
	isLoading,
	bottomLabel,
	leftLabel,
	topLabel,
	topLabelIcon,
}: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const chartRef = useRef(null);
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [highlightedItem, setHighLightedItem] =
		useState<HighlightItemData | null>(null);
	const [labels, setLabels] = useState<string[]>([]);
	const [seriesData, setSeriesData] = useState<any>([]);
	const [seriesDataStack, setSeriesDataStack] = useState<any>([]);
	const [layout, setLayout] = useState<"vertical" | "horizontal">("horizontal");
	const [localSelectedCarList, setLocalCarList] = useState<any>([]);
	const [chartLabel, setChartLabel] = useState("");
	// eslint-disable-next-line no-unused-vars
	const [downloadReport, setDownloadReport] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, openPdfViewer, chartReportTheme, blockFilters } =
		useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// eslint-disable-next-line no-undef
	const chartSettingsH: Partial<BarChartProps> = {
		yAxis: [
			{
				id: "barCategoriesY",
				data: labels,
				scaleType: "band",
				tickLabelStyle: {
					fontSize: 12,
				},
				tickLabelPlacement: "middle",
				tickPlacement: "middle",
			},
		],
	};
	// eslint-disable-next-line no-undef
	const chartSettingsV: Partial<BarChartProps> = {
		xAxis: [
			{
				id: "barCategoriesX",
				data: labels,
				scaleType: "band",
				tickLabelStyle: {
					fontSize: 12,
				},
				tickLabelPlacement: "middle",
				tickPlacement: "middle",
			},
		],
		yAxis: undefined,
	};

	const getCharNoDataMessage = () => {
		if (filters.carsList.length === 0) {
			return "general.noData";
		}
		if (localSelectedCarList.length === 0) {
			return "general.selectSomeCar";
		}
		if (data && data.length === 0) {
			return "general.monthHasNoData";
		}
		return "general.noData";
	};

	const arraysAreEqual = (arr1: any, arr2: any) => {
		if (arr1.length !== arr2.length) {
			return false;
		}

		return arr1.every((value: any, index: any) => value === arr2[index]);
	};

	const captureChartAsImage = () => {
		if (chartRef.current) {
			html2canvas(chartRef.current).then((canvas) => {
				const imgData: any = canvas.toDataURL("image/png");
				const copy: ReportSliceFiltersInterface = structuredClone(filters);
				copy.urlChartImage = imgData;
				dispatch(updateFilters(copy));
			});
		}
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		dispatch(updateBlockFilters(true));
		if (localSelectedCarList && localSelectedCarList.length > 0 && data) {
			setSeriesData(
				generateBarsDataFault(
					data,
					false,
					localSelectedCarList,
					filters.chartMirror
				)
			);
			setSeriesDataStack(
				generateBarsDataFault(
					data,
					true,
					localSelectedCarList,
					filters.chartMirror
				)
			);
			const responseLabels = getAxisLabels(data);
			setLabels(responseLabels);
			setChartLabel(
				`${moment(filters.startDate).format("MMMM Do YYYY")} / ${moment(
					filters.endDate
				).format("MMMM Do YYYY")}`
			);
		} else {
			setSeriesData([]);
			setSeriesDataStack([]);
			setChartLabel("");
			setLabels(getAxisLabels(data));
		}
		setTimeout(() => {
			dispatch(updateBlockFilters(false));
		}, 1000);
	}, [localSelectedCarList, data, filters.chartMirror]);

	useEffect(() => {
		if (
			filters.chartType === "bar_vertical" ||
			filters.chartType === "bar_stack_vertical"
		) {
			setLayout("vertical");
		} else if (
			filters.chartType === "bar_horizontal" ||
			filters.chartType === "bar_stack_horizontal"
		) {
			setLayout("horizontal");
		}
	}, [filters.chartType]);

	useEffect(() => {
		if (!arraysAreEqual(filters.selectedCarsList, localSelectedCarList)) {
			setLocalCarList(filters.selectedCarsList);
		}
	}, [filters.selectedCarsList]);

	useEffect(() => {
		if (openPdfViewer) {
			captureChartAsImage();
		}
	}, [openPdfViewer]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={openPdfViewer || blockFilters}>
			<div style={{ opacity: openPdfViewer ? 0 : 1 }}>
				<Box sx={{ width: "100%", padding: "10px" }} ref={chartRef}>
					<div
						className='generalStyles__width100 generalStyles__textCenter'
						style={{
							fontSize: "20px",
							fontWeight: "bolder",
							backgroundColor: `${getChartBackgroundColor(chartReportTheme)}`,
							color: `${getChartTextColor(chartReportTheme)}`,
							paddingTop: "20px",
							paddingBottom: "5px",
						}}
					>
						<span>{topLabel}</span>
						{topLabelIcon === "UP" && (
							<FontAwesomeIcon
								icon={faUpLong}
								fontSize={23}
								className='generalStyles__mlFix generalStyles__success'
							/>
						)}
						{topLabelIcon === "DOWN" && (
							<FontAwesomeIcon
								icon={faDownLong}
								fontSize={23}
								className='generalStyles__mlFix generalStyles__error'
							/>
						)}
					</div>
					<div className='generalStyles__flex'>
						<div
							style={{
								fontSize: "20px",
								fontWeight: "bolder",
								backgroundColor: `${getChartBackgroundColor(chartReportTheme)}`,
								textAlign: "center",
								color: `${getChartTextColor(chartReportTheme)}`,
							}}
							className='generalStyles__flexCenter'
						>
							<span style={{ transform: "rotate(270deg)" }}>
								{filters.chartType === "bar_horizontal" ||
								filters.chartType === "bar_stack_horizontal"
									? bottomLabel
									: leftLabel}
							</span>
						</div>
						<BarChart
							loading={isLoading}
							axisHighlight={{ x: "line", y: "line" }}
							slotProps={{
								legend: {
									direction: "row",
									position: { vertical: "top", horizontal: "middle" },
									labelStyle: {
										fontSize: 14,
										fill: `${getChartTextColor(chartReportTheme)}`,
									},
								},
								// Custom loading message
								loadingOverlay: { message: "Loading..." },
								// Custom message for empty chart
								noDataOverlay: { message: t(`${getCharNoDataMessage()}`) },
							}}
							sx={{
								[`.${axisClasses.root}`]: {
									[`.${axisClasses.tick}, .${axisClasses.line}`]: {
										stroke: `${getChartTicklineColor(chartReportTheme)}`,
										strokeWidth: 3,
									},
									[`.${axisClasses.tickLabel}`]: {
										fill: `${getChartTextColor(chartReportTheme)}`,
									},
								},
								[`.MuiBarLabel-root	`]: {
									stroke: "white",
								},
								[`.MuiChartsAxisHighlight-root `]: {
									stroke: `${getChartAxisHighlight(chartReportTheme)}`,
								},
								[`.css-1f57y8b `]: {
									fill: `${getChartTextColor(chartReportTheme)}`,
								},
								backgroundColor: `${getChartBackgroundColor(chartReportTheme)}`,
							}}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...(layout === "vertical" ? chartSettingsV : chartSettingsH)}
							series={
								filters.chartType === "bar_stack_vertical" ||
								filters.chartType === "bar_stack_horizontal"
									? seriesDataStack
									: seriesData
							}
							barLabel='value'
							height={450}
							tooltip={{ trigger: "item" }}
							highlightedItem={highlightedItem}
							onHighlightChange={setHighLightedItem}
							layout={layout}
						/>
					</div>
					<div
						className='generalStyles__width100 generalStyles__textCenter'
						style={{
							fontSize: "20px",
							fontWeight: "bolder",
							backgroundColor: `${getChartBackgroundColor(chartReportTheme)}`,
							color: `${getChartTextColor(chartReportTheme)}`,
							paddingBottom: "10px",
							marginTop: "-5px",
						}}
					>
						<span>
							{filters.chartType === "bar_horizontal" ||
							filters.chartType === "bar_stack_horizontal"
								? leftLabel
								: bottomLabel}
						</span>
						<br />
						<span className='generalStyles__info'>{chartLabel}</span>
					</div>
				</Box>
			</div>
			{/** ******************************************* */}
			{/** DOWNLOAD REPORT */}
			<ReportsAlertDownloadModal setDownloadReport={setDownloadReport} />
		</Spin>
	);
}

export default ChartBar;
