import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Button, Select } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { updateRefreshTable } from "../../../redux/actions/actionsSlice";
import { useLazyGetOrganizationAndChildsQuery } from "../../../redux/organizations/organizationAPI";
import GETJwtToken from "../../../redux/authentication/authentication";
import { updateSelectedOrganization } from "../../../redux/mapView/mapViewSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import { OrganizationInterface } from "../../../interfaces/Organizations.interface";

function MapViewHeader() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const { Option } = Select;

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [organizations, setOrganizations] = useState<OrganizationInterface[]>(
    []
  );
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { permissions } = useSelector((state: any) => state.user);
  const { userLogged } = useSelector((state: RootState) => state.home);
  const { selectedOrganization } = useSelector(
    (state: RootState) => state.mapView
  );
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    triggerGetOrganizations,
    { data: dataGetOrganizations, isLoading: isLoadingGetOrganizations },
  ] = useLazyGetOrganizationAndChildsQuery();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const initialData = async () => {
    const token = await GETJwtToken();
    triggerGetOrganizations({
      page: 1,
      limit: 9999,
      token,
      orgId: userLogged.org.id,
    });
  };
  const handleReloadPage = () => {
    dispatch(updateRefreshTable(true));
    setTimeout(() => {
      dispatch(updateRefreshTable(false));
    }, 1000);
  };

  // ************************************************ */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (dataGetOrganizations && dataGetOrganizations.data) {
      if (dataGetOrganizations.data.children) {
        setOrganizations([
          {
            id: dataGetOrganizations.data.id,
            address: dataGetOrganizations.data.address,
            city: dataGetOrganizations.data.city,
            country: dataGetOrganizations.data.country,
            name: dataGetOrganizations.data.name,
            phone_code: dataGetOrganizations.data.phone_code,
            phone: dataGetOrganizations.data.phone,
            state: dataGetOrganizations.data.state,
            type: dataGetOrganizations.data.type,
          },
          ...dataGetOrganizations.data.children,
        ]);
      } else {
        setOrganizations(dataGetOrganizations.data);
      }
    }
  }, [dataGetOrganizations]);

  useEffect(() => {
    initialData();
  }, []);

  return (
    <div className="generalStyles__spaceBetween">
      <div className="generalStyles__mlFix mt-1" style={{ width: 280 }}>
        <Select
          showSearch
          placeholder={t("userManagement.organization")}
          optionFilterProp="children"
          className="generalStyles__width100"
          loading={isLoadingGetOrganizations}
          allowClear
          onChange={(value: string) => {
            dispatch(updateSelectedOrganization(value));
          }}
          value={selectedOrganization}
          size="small"
        >
          {organizations.map((item: OrganizationInterface) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
      {validatePermission("data-blocks-catalog", permissions) && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "4px",
          }}
        >
          <div className="generalStyles__mlFix mt-1">
            <Button
              className="buttonStyle__10"
              icon={<FontAwesomeIcon icon={faArrowsRotate} />}
              onClick={() => {
                handleReloadPage();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default MapViewHeader;
