import React from "react";
// REDUX
import { useSelector } from "react-redux";
// ELEVATOR ICONS ACTIONS
import actionAlertDark from "../../../assets/icons/actions/action-alert-dark.svg";
import actionAlertLight from "../../../assets/icons/actions/action-alert-light.svg";
import actionCloseDark from "../../../assets/icons/actions/action-close-dark.svg";
import actionCloseLight from "../../../assets/icons/actions/action-close-light.svg";
import actionLockDark from "../../../assets/icons/actions/action-lock-dark.svg";
import actionLockLight from "../../../assets/icons/actions/action-lock-light.svg";
import actionOpenDark from "../../../assets/icons/actions/action-open-dark.svg";
import actionOpenLight from "../../../assets/icons/actions/action-open-light.svg";
// ELEVATOR ICONS ELEVATOR DOUBLE
import elDoubleIndicatorDark from "../../../assets/icons/elevatorDouble/el-double-indicator-dark.svg";
import elDoubleIndicatorLight from "../../../assets/icons/elevatorDouble/el-double-indicator-light.svg";
import elDoubleOutServiceDark from "../../../assets/icons/elevatorDouble/el-double-out-service-dark.svg";
import elDoubleOutServiceLight from "../../../assets/icons/elevatorDouble/el-double-out-service-light.svg";
import elFrontCloseRearClose from "../../../assets/icons/elevatorDouble/el-front-close-rear-close.svg";
import elFrontCloseRearOpen from "../../../assets/icons/elevatorDouble/el-front-close-rear-open.svg";
import elFrontCloseRearOpening from "../../../assets/icons/elevatorDouble/el-front-close-rear-opening.svg";
import elFrontOpenRearClose from "../../../assets/icons/elevatorDouble/el-front-open-rear-close.svg";
import elFrontOpenRearOpen from "../../../assets/icons/elevatorDouble/el-front-open-rear-open.svg";
import elFrontOpeningRearClose from "../../../assets/icons/elevatorDouble/el-front-opening-rear-close.svg";
import elFrontOpeningRearOpening from "../../../assets/icons/elevatorDouble/el-front-opening-rear-opening.svg";
// ELEVATOR ICONS ELEVATOR SINGLE
import elClose from "../../../assets/icons/elevatorSingle/el-close.svg";
import elOpen from "../../../assets/icons/elevatorSingle/el-open.svg";
import elOpening from "../../../assets/icons/elevatorSingle/el-opening.svg";
import elOutIndicatorDark from "../../../assets/icons/elevatorSingle/el-out-indicator-dark.svg";
import elOutIndicatorLight from "../../../assets/icons/elevatorSingle/el-out-indicator-light.svg";
import elOutServiceDark from "../../../assets/icons/elevatorSingle/el-out-service-dark.svg";
import elOutServiceLight from "../../../assets/icons/elevatorSingle/el-out-service-light.svg";
// ELEVATOR ICONS ELEVATOR STATUS
import elDown from "../../../assets/icons/elevatorStatus/el-down.svg";
import elLockedDark from "../../../assets/icons/elevatorStatus/el-locked-dark.svg";
import elLockedLight from "../../../assets/icons/elevatorStatus/el-locked-ligh.svg";
import elNotAllowedDark from "../../../assets/icons/elevatorStatus/el-not-allowed-dark.svg";
import elNotAllowedLight from "../../../assets/icons/elevatorStatus/el-not-allowed-light.svg";
import elUp from "../../../assets/icons/elevatorStatus/el-up.svg";
import elWarning from "../../../assets/icons/elevatorStatus/el-warning.svg";
import elGoalDark from "../../../assets/icons/elevatorStatus/el-goal-dark.svg";
import elGoalLight from "../../../assets/icons/elevatorStatus/el-goal-light.svg";
import elGoalHallCall from "../../../assets/icons/elevatorStatus/el-goal-hall-call.svg";
import elGoalRed from "../../../assets/icons/elevatorStatus/el-goal-red.svg";
import elGoalBlue from "../../../assets/icons/elevatorStatus/el-goal-blue.svg";
import elGoalGreen from "../../../assets/icons/elevatorStatus/el-goal-green.svg";
// ESCALATOR ICONS STATUS
import esDownDark from "../../../assets/icons/escalator/es-dark-down-4.svg";
import esDownLight from "../../../assets/icons/escalator/es-light-down-4.svg";
import esUpDark from "../../../assets/icons/escalator/es-dark-up-4.svg";
import esUpLight from "../../../assets/icons/escalator/es-light-up-4.svg";
import esLockedDark from "../../../assets/icons/escalator/es-dark-locked.svg";
import esLockedLight from "../../../assets/icons/escalator/es-light-locked.svg";
import esStoppedDark from "../../../assets/icons/escalator/es-dark-stopped.svg";
import esStoppedLight from "../../../assets/icons/escalator/es-light-stopped.svg";
import esWarningDark from "../../../assets/icons/escalator/es-dark-warning.svg";
import esWarningLight from "../../../assets/icons/escalator/es-light-warning.svg";
import esNotAllowedDark from "../../../assets/icons/escalator/es-dark-not-allowed.svg";
import esNotAllowedLight from "../../../assets/icons/escalator/es-light-not-allowed.svg";
import esRunDark from "../../../assets/icons/escalator/es-dark-run.svg";
import esRunLight from "../../../assets/icons/escalator/es-light-run.svg";
// MOVING WALKS ICONS STATUS
import weBackwardLight from "../../../assets/icons/movingWalk/we-light-backward-4.svg";
import weBackwardDark from "../../../assets/icons/movingWalk/we-dark-backward-4.svg";
import weFordwardLight from "../../../assets/icons/movingWalk/we-light-forward-4.svg";
import weFordwardDark from "../../../assets/icons/movingWalk/we-dark-forward-4.svg";
import weWarningLight from "../../../assets/icons/movingWalk/we-light-warning.svg";
import weWarningDark from "../../../assets/icons/movingWalk/we-dark-warning.svg";
import weLockedLight from "../../../assets/icons/movingWalk/we-light-locked.svg";
import weLockedDark from "../../../assets/icons/movingWalk/we-dark-locked.svg";
import weNotAllowedLight from "../../../assets/icons/movingWalk/we-light-not-allowed.svg";
import weNotAllowedDark from "../../../assets/icons/movingWalk/we-dark-not-allowed.svg";
import weStoppedLight from "../../../assets/icons/movingWalk/we-light-stopped.svg";
import weStoppedDark from "../../../assets/icons/movingWalk/we-dark-stopped.svg";
import weRunDark from "../../../assets/icons/movingWalk/we-dark-run.svg";
import weRunLight from "../../../assets/icons/movingWalk/we-light-run.svg";
import map from "../../../assets/icons/others/map.svg";
import mapDark from "../../../assets/icons/others/map-dark.svg";
// INTERFACES
import { IconDefinitionIconsInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function IconDefinitionIcons({
  liftType,
  icon,
  iconSize,
  isMap,
  expanded,
}: IconDefinitionIconsInterface) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const { theme } = useSelector((state: RootState) => state.home);

  const data: any = {
    ELEVATOR: {
      actionAlert: {
        icon: theme === "light" ? actionAlertLight : actionAlertDark,
      },
      actionClose: {
        icon: theme === "light" ? actionCloseLight : actionCloseDark,
      },
      actionLock: {
        icon: theme === "light" ? actionLockLight : actionLockDark,
      },
      actionOpen: {
        icon: theme === "light" ? actionOpenLight : actionOpenDark,
      },
      elDoubleIndicator: {
        icon:
          theme === "light" ? elDoubleIndicatorLight : elDoubleIndicatorDark,
      },
      elDoubleOutService: {
        icon:
          theme === "light" ? elDoubleOutServiceLight : elDoubleOutServiceDark,
      },
      elFrontCloseRearClose: { icon: elFrontCloseRearClose },
      elFrontCloseRearOpen: { icon: elFrontCloseRearOpen },
      elFrontCloseRearOpening: { icon: elFrontCloseRearOpening },
      elFrontOpenRearClose: { icon: elFrontOpenRearClose },
      elFrontOpenRearOpen: { icon: elFrontOpenRearOpen },
      elFrontOpeningRearClose: { icon: elFrontOpeningRearClose },
      elFrontOpeningRearOpening: { icon: elFrontOpeningRearOpening },
      elClose: { icon: elClose },
      elOpen: { icon: elOpen },
      elOpening: { icon: elOpening },
      elOutIndicator: {
        icon: theme === "light" ? elOutIndicatorLight : elOutIndicatorDark,
      },
      elOutService: {
        icon: theme === "light" ? elOutServiceLight : elOutServiceDark,
      },
      elDown: { icon: elDown },
      elLocked: {
        icon: theme === "light" ? elLockedLight : elLockedDark,
      },
      elNotAllowed: {
        icon: theme === "light" ? elNotAllowedLight : elNotAllowedDark,
      },
      elUp: { icon: elUp },
      elWarning: { icon: elWarning },
      elGoal: {
        icon: theme === "light" ? elGoalLight : elGoalDark,
      },
      elGoalHallCall: { icon: elGoalHallCall },
      elGoalRed: { icon: elGoalRed },
      elGoalBlue: { icon: elGoalBlue },
      elGoalGreen: { icon: elGoalGreen },
    },
    ESCALATOR: {
      esDown: {
        icon: theme === "light" ? esDownLight : esDownDark,
      },
      esUp: {
        icon: theme === "light" ? esUpLight : esUpDark,
      },
      esLocked: {
        icon: theme === "light" ? esLockedLight : esLockedDark,
      },
      esStopped: {
        icon: theme === "light" ? esStoppedLight : esStoppedDark,
      },
      esWarning: {
        icon: theme === "light" ? esWarningLight : esWarningDark,
      },
      esNotAllowed: {
        icon: theme === "light" ? esNotAllowedLight : esNotAllowedDark,
      },
      esRun: {
        icon: theme === "light" ? esRunLight : esRunDark,
      },
    },
    MOVINGWALK: {
      weBackward: {
        icon: theme === "light" ? weBackwardLight : weBackwardDark,
      },
      weFordward: {
        icon: theme === "light" ? weFordwardLight : weFordwardDark,
      },
      weLocked: {
        icon: theme === "light" ? weLockedLight : weLockedDark,
      },
      weStopped: {
        icon: theme === "light" ? weStoppedLight : weStoppedDark,
      },
      weWarning: {
        icon: theme === "light" ? weWarningLight : weWarningDark,
      },
      weNotAllowed: {
        icon: theme === "light" ? weNotAllowedLight : weNotAllowedDark,
      },
      weRun: {
        icon: theme === "light" ? weRunLight : weRunDark,
      },
    },
    MAP: {
      map: { icon: theme === "light" ? map : mapDark },
    },
  };

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************ */
  // USE EFFECT ************************************* */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <>
      {isMap ? (
        <>
          {data &&
            data[liftType] &&
            data[liftType][icon] &&
            data[liftType][icon].icon && (
              <img
                className={`${
                  expanded
                    ? `mapViewStyles__icon__${
                        ["elevator", "map"].includes(
                          liftType.toLocaleLowerCase()
                        )
                          ? "elevator"
                          : "escalator"
                      }__expanded`
                    : `mapViewStyles__icon__${
                        ["elevator", "map"].includes(
                          liftType.toLocaleLowerCase()
                        )
                          ? "elevator"
                          : "escalator"
                      }__minimized`
                }`}
                src={data[liftType][icon].icon}
                alt=""
              />
            )}
        </>
      ) : (
        <>
          {data &&
            data[liftType] &&
            data[liftType][icon] &&
            data[liftType][icon].icon && (
              <img
                src={data[liftType][icon].icon}
                alt=""
                height={iconSize || 20}
                width={iconSize || 20}
              />
            )}
        </>
      )}
    </>
  );
}

export default IconDefinitionIcons;
