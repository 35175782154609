export enum MapViewDirections {
  UP = "UP",
  DOWN = "DOWN",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum MapViewTypes {
  LIFT = "LIFT",
  MAP = "MAP",
  ESCALATOR = "ESCALATOR",
  MOVINGWALK = "MOVINGWALK",
}

export interface MapViewPin {
  id: string;
  text: string;
  direction: MapViewDirections;
  x?: number;
  y?: number;
  type: MapViewTypes;
}

export interface MapViewInterface {
  [key: string]: any; // Index signature
  name: string;
  start: boolean;
  file: any;
  setup: string;
  org_id: string;
}

export interface MapViewPin2Interface {
  boxSize: "xs" | "sm" | "md" | "lg" | "xl";
  fault: boolean;
  isEscalator: boolean;
  rowDirection: "top" | "left" | "bottom" | "right";
  pinDirection: "horizontal" | "vertical";
  icon: any;
  iconHeight: number;
  iconWidth: number;
}

export interface MapViewPin3Interface {
  carName: string;
  serviceMode: string;
  icon: string;
  firstStop: string;
  secondStop: string;
  nextStop: boolean;
  direction: "top" | "left" | "bottom" | "right";
  orientation: "horizontal" | "vertical";
  fault: boolean;
  liftType: "elevator" | "escalator" | "movingwalk" | "map";
}
