import React from "react";
// NAVIGATION
import { useLocation, useNavigate } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Breadcrumb } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";

library.add(faUser);

function MapViewBreadcrumb() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const navigate = useNavigate();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const location = useLocation();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const navigateToTable = () => {
		navigate({
			pathname: "/edge-management/map-view",
		});
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Breadcrumb className=''>
			<Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
			<Breadcrumb.Item
				onClick={navigateToTable}
				className={`${
					location.pathname === "/edge-management/map-view"
						? "generalStyles__breadcrumbItem"
						: "generalStyles__breadcrumbItemInactive"
				}`}
			>
				{t("general.mapViewTable")}
			</Breadcrumb.Item>
			{location.pathname === "/edge-management/map-view/configuration" && (
				<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
					{t("general.mapViewConfig")}
				</Breadcrumb.Item>
			)}
		</Breadcrumb>
	);
}

export default MapViewBreadcrumb;
