import React, { useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button, Tooltip, TimePicker, Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// MOMENT DATE FORMAT
import moment from "moment";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faClock,
	faUser,
	faAdd,
	faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerManageSchedules } from "../../../redux/notifications/notificationSlice";
// COMPONENTS
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
import DrawerCloseSaveButton from "../../components/drawer/DrawerCloseSaveButton";
import DrawerRowItem from "../../components/drawer/DrawerRowItem";
// INTERFACES
import { RootState } from "../../../app/store";

function NotificationSchedulesDrawerManageSchedules() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [exclusionRangeTime, setExclusionRangeTime] = useState<any>([
		"00:00",
		"23:59",
	]);
	const [day, setDay] = useState<string>("monday");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerManageSchedules } = useSelector(
		(state: RootState) => state.notification
	);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerManageSchedules(false));
	};

	const onClickSave = () => {
		dispatch(updateOpenDrawerManageSchedules(false));
	};

	const onChangeExclusionTimeRange = (
		value: any,
		dateString: [string, string] | string
	) => {
		setExclusionRangeTime(dateString);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='550'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerManageSchedules}
		>
			<div>
				<DrawerRowDivider icon={faClock} title={t("general.information")} />
			</div>
			<div className={`drawer__box__${theme}`}>
				<DrawerRowItem
					title='ID'
					value='a4a8s5s78s-ad5fadsf-823jkf8923-adsfasd'
				/>
				<DrawerRowItem title={t("general.message")} value='Fault 1' />
				<DrawerRowItem title={t("general.bank")} value='West Loch ES1' />
				<DrawerRowItem title={t("general.organization")} value='West Loch' />
				<DrawerRowItem title={t("general.alert")} value='WHATSAPP' />
			</div>
			<br />
			<div>
				<DrawerRowDivider
					icon={faUser}
					title={t("general.createNewSchedule")}
				/>
			</div>
			<div className={`drawer__box__${theme}`}>
				<div className='generalStyles__flex '>
					<div>
						<p className='drawer__title'>{t("general.day")}</p>
					</div>
					<div className='drawer__textContainer' style={{ width: "230px" }}>
						<div className='generalStyles__flex'>
							<Select
								showSearch
								optionFilterProp='children'
								size='small'
								allowClear
								style={{ width: 250 }}
								filterOption={(input: any, option: any) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}
								value={day}
								onChange={(value) => {
									setDay(value);
								}}
							>
								{[
									"monday",
									"tuesday",
									"wednesday",
									"thursday",
									"friday",
									"saturday",
									"sunday",
								].map((item: any) => (
									<Option key={item} value={item}>
										{t(`general.${item}`)}
									</Option>
								))}
							</Select>
						</div>
					</div>
				</div>
				<div className='generalStyles__flex mt-2'>
					<div>
						<p className='drawer__title'>{t("general.schedule")}</p>
					</div>
					<div className='drawer__textContainer generalStyles__width100'>
						<div className='generalStyles__flex'>
							<TimePicker.RangePicker
								format='HH:mm'
								className='generalStyles__width100'
								onChange={onChangeExclusionTimeRange}
								value={[
									moment(exclusionRangeTime[0], "HH:mm"),
									moment(exclusionRangeTime[1], "HH:mm"),
								]}
								allowClear={false}
							/>
							<Tooltip title='Add new exclusion time range' placement='topLeft'>
								<Button
									type='primary'
									shape='circle'
									className='generalStyles__mlFix'
									icon={<FontAwesomeIcon icon={faAdd} />}
								/>
							</Tooltip>
						</div>
					</div>
				</div>
			</div>
			<br />
			<div>
				<DrawerRowDivider
					icon={faCalendarDays}
					title={t("general.schedules")}
				/>
			</div>
			<div className={`drawer__box__${theme}`}>
				<div className='generalStyles__flex '>
					<div>
						<p className='drawer__title'>{t("general.monday")}</p>
					</div>
					<div className='drawer__textContainer generalStyles__width100'>
						<div className='generalStyles__flex'>11:00am To 11:30am</div>
					</div>
				</div>
				<div className='generalStyles__flex '>
					<div>
						<p className='drawer__title'>{t("general.monday")}</p>
					</div>
					<div className='drawer__textContainer generalStyles__width100'>
						<div className='generalStyles__flex'>11:00am To 11:30am</div>
					</div>
				</div>
				<div className='generalStyles__flex '>
					<div>
						<p className='drawer__title'>{t("general.monday")}</p>
					</div>
					<div className='drawer__textContainer generalStyles__width100'>
						<div className='generalStyles__flex'>11:00am To 11:30am</div>
					</div>
				</div>
			</div>
			{/** ---------------------------------------------------- */}
			{/** COMPONENT CLOSE SAVE BUTTON */}
			<DrawerCloseSaveButton
				onClickClose={onClickClose}
				onClickSave={onClickSave}
				disableSaveButton={false}
			/>
			<br />
		</Drawer>
	);
}

export default NotificationSchedulesDrawerManageSchedules;
