import React from "react";
import ReactDOM from "react-dom/client";
import i18next from "i18next";
import { Provider } from "react-redux";
import { Auth, Amplify } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { store } from "./app/store";
//
import App from "./App";
import "./index.less";
import "bootstrap/dist/css/bootstrap.min.css";
//
import globalES from "./translations/es/global.json";
import globalEN from "./translations/en/global.json";
//
import config from "./config";
//
// import { register } from './swDev'
// import swDev from './swDev2'

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
  blue: `${process.env.PUBLIC_URL}/blue-theme.css`,
};

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    es: {
      global: globalES,
    },
    en: {
      global: globalEN,
    },
  },
});

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "notes",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

Auth.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: config.cognito.OAUTH_DOMAIN, 
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'], // Add the admin scope here
      redirectSignIn: config.cognito.REDIRECT_SIGN_IN , // URL to redirect to after sign-in
      redirectSignOut: config.cognito.REDIRECT_SIGN_OUT, // URL to redirect to after sign-out
      responseType: 'code' // Authorization code grant
    },      
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "notes",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("The element #portal wasn't found");
}

const root = ReactDOM.createRoot(rootElement);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme="light">
          <App />
        </ThemeSwitcherProvider>
      </I18nextProvider>
    </Provider>
  </BrowserRouter>
);

// register()
// swDev()
