import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tooltip } from "antd";
// COMPONENTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
import {
	MapViewDirections,
	MapViewPin,
	MapViewTypes,
} from "../../../interfaces/MapView.interface";
import GlobalAlert2 from "../../home/GlobalAlert2";
import {
	updateMapViewItem,
	updateMapViewPinArray,
	updateOpenSelectMode,
} from "../../../redux/mapView/mapViewSlice";
// REDUX
import GETJwtToken from "../../../redux/authentication/authentication";
import { usePostMapViewPinMutation } from "../../../redux/mapView/mapViewAPI";

function MapViewPillState({
	id,
	direction,
	text,
	x,
	y,
	type,
}: {
	id: string;
	direction: MapViewDirections;
	text: string;
	x: number;
	y: number;
	type: MapViewTypes;
}) {
	const dispatch = useDispatch();
	const { mapViewArray } = useSelector((state: any) => state.mapView);

	const getTransformBasedOnDirection = () => {
		if (direction === MapViewDirections.UP) {
			return "rotate(180deg) translateY(150%)";
		}
		if (direction === MapViewDirections.DOWN) {
			return "rotate(0deg) translateY(150%)";
		}
		if (direction === MapViewDirections.LEFT) {
			return "rotate(90deg) translate(0, 150%)";
		}
		if (direction === MapViewDirections.RIGHT) {
			return "rotate(270deg) translate(0, 150%)";
		}
		return "rotate(0deg)";
	};

	const handleClick = () => {
		if (type === MapViewTypes.MAP) {
			// get the selected map view data
			const selectedMapView = mapViewArray.find(
				(mapView: any) => mapView.id === id
			);
			// update my element
			dispatch(updateMapViewItem(selectedMapView));
		}
	};

	return (
		<Tooltip title={text}>
			<Button
				onClick={handleClick}
				className='buttonStyle__1'
				style={{
					height: "32px",
					width: "32px",
					position: "absolute",
					top: `${y}px`,
					left: `${x}px`,
					borderRadius: "10%",
				}}
			>
				<div
					style={{
						position: "relative",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: "100%",
					}}
				>
					<div
						className='component__mapViewPin__tip'
						style={{
							transform: getTransformBasedOnDirection(),
						}}
					/>
					<span
						style={{
							zIndex: 10,
						}}
					>
						{type === MapViewTypes.MAP && <FontAwesomeIcon icon={faMap} />}
						{type === MapViewTypes.LIFT && (
							<IconDefinitionIcons
								isMap={false}
								expanded={false}
								liftType='ELEVATOR'
								icon='elOutIndicator'
								iconSize={16}
							/>
						)}
						{type === MapViewTypes.MOVINGWALK && (
							<IconDefinitionIcons
								isMap={false}
								expanded={false}
								liftType='MOVINGWALK'
								icon='weStopped'
								iconSize={40}
							/>
						)}
						{type === MapViewTypes.ESCALATOR && (
							<IconDefinitionIcons
								isMap={false}
								expanded={false}
								liftType='ESCALATOR'
								icon='esStopped'
								iconSize={40}
							/>
						)}
					</span>
				</div>
			</Button>
		</Tooltip>
	);
}

function MapViewMap() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const buttonRef = React.useRef<HTMLButtonElement>(null);
	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [buttonProperties, setButtonProperties] = React.useState({
		width: 0,
		height: 0,
	});
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openSelectMode, selectedItem, mapViewPinArray, mapViewItem } =
		useSelector((state: any) => state.mapView);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerPostMapViewPin,
		{
			isSuccess: isSuccessPostMapView,
			isError: isErrorPostMapView,
			error: errorPostMapView,
		},
	] = usePostMapViewPinMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const handleResize = () => {
		const width = buttonRef.current?.clientWidth || 0;
		const height = buttonRef.current?.clientHeight || 0;
		if (width === 0 || height === 0) return;
		setButtonProperties({
			width,
			height,
		});
	};

	const getXMargin = (direction: MapViewDirections) => {
		switch (direction) {
			case MapViewDirections.LEFT:
				return -16;
			case MapViewDirections.RIGHT:
				return 32;
			default:
				return 0;
		}
	};

	const getYMargin = (direction: MapViewDirections) => {
		switch (direction) {
			case MapViewDirections.DOWN:
				return 32;
			case MapViewDirections.LEFT:
				return 8;
			case MapViewDirections.RIGHT:
				return 16;
			case MapViewDirections.UP:
				return -8;
			default:
				return 0;
		}
	};

	const handleClickMap = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if (!openSelectMode) return;
		const offsetX = e.nativeEvent.offsetX - getXMargin(selectedItem.direction);
		const offsetY = e.nativeEvent.offsetY - getYMargin(selectedItem.direction);

		const relativeX = offsetX / buttonProperties.width;
		const relativeY = offsetY / buttonProperties.height;

		// save in redux
		dispatch(
			updateMapViewPinArray([
				...mapViewPinArray,
				{
					...selectedItem,
					x: relativeX,
					y: relativeY,
				},
			])
		);
		// Save map view in the backend
		const token = await GETJwtToken();
		const body = {
			id: mapViewItem.id,
			name: mapViewItem.name,
			org_id: mapViewItem.org_id,
			start: mapViewItem.start,
			setup: {
				pins: [
					...mapViewPinArray,
					{
						...selectedItem,
						x: relativeX,
						y: relativeY,
					},
				],
			},
		};
		triggerPostMapViewPin({
			token,
			body,
		});
		// turn of select mode
		dispatch(updateOpenSelectMode(!openSelectMode));
	};
	// ************************************************ */
	// USE EFFECT ************************************* */

	React.useEffect(() => {
		const pins = mapViewItem?.setup?.pins ?? [];
		dispatch(
			updateMapViewPinArray(
				pins?.map((pin: any) => ({
					...pin,
				}))
			)
		);
		handleResize();
	}, [mapViewItem]);

	React.useEffect(() => {
		window.addEventListener("resize", handleResize, false);
		handleResize();
	}, [buttonRef.current]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<>
			{/** BODY */}
			<div
				style={{
					position: "relative",
				}}
			>
				<Button
					disabled={!openSelectMode}
					style={{
						filter: openSelectMode && "brightness(0.8)",
						width: "100%",
						height: "100%",
					}}
					ref={buttonRef}
					onClick={handleClickMap}
				>
					<img
						src={mapViewItem?.s3_url}
						onLoad={handleResize}
						alt='Example Blueprint'
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
					/>
				</Button>
				{mapViewPinArray?.map((lift: MapViewPin) => {
					if (!lift.x || !lift.y) return null;
					return (
						<MapViewPillState
							key={lift.id}
							id={lift.id}
							text={lift.text}
							direction={lift.direction}
							type={lift.type}
							x={lift.x * buttonProperties.width}
							y={lift.y * buttonProperties.height}
						/>
					);
				})}
			</div>
			<GlobalAlert2
				isError={isErrorPostMapView}
				isSuccess={isSuccessPostMapView}
				requestType='POST'
				error={errorPostMapView}
				name='Action'
			/>
		</>
	);
}

export default MapViewMap;
