import React from "react";
// ANT DESIGN COMPONENTS
import { DownOutlined } from "@ant-design/icons";
// eslint-disable-next-line no-unused-vars
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTools } from "@fortawesome/free-solid-svg-icons";
import {
  updateFloorsEnabled,
  updateNamesEnabled,
  updateOtsEnabled,
  updateShowOriginal,
} from "../../../redux/mapView/mapViewSlice";

function MonitoringMapViewConfig() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { otsEnabled, floorsEnabled, namesEnabled, showOriginal } = useSelector(
    (state: any) => state.mapView
  );

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  const handleOTS = () => {
    dispatch(updateOtsEnabled(!otsEnabled));
  };

  const handleFloor = () => {
    dispatch(updateFloorsEnabled(!floorsEnabled));
  };

  const handleNames = () => {
    dispatch(updateNamesEnabled(!namesEnabled));
  };

  const handleShowOriginal = () => {
    dispatch(updateShowOriginal(!showOriginal));
  };

  // ************************************************ */
  // USE EFFECT ************************************* */

  // ************************************************ */
  // COMPONENT ************************************** */
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <FontAwesomeIcon icon={namesEnabled ? faEye : faEyeSlash} /> Unit
          Names
        </>
      ),
      onClick: handleNames,
    },
    {
      key: "2",
      label: (
        <>
          <FontAwesomeIcon icon={floorsEnabled ? faEye : faEyeSlash} /> Floors
        </>
      ),
      onClick: handleFloor,
    },
    {
      key: "3",
      label: (
        <>
          <FontAwesomeIcon icon={otsEnabled ? faEye : faEyeSlash} /> Service
          Mode
        </>
      ),
      onClick: handleOTS,
    },
    {
      key: "4",
      label: (
        <>
          <FontAwesomeIcon icon={showOriginal ? faEye : faEyeSlash} /> Original
        </>
      ),
      onClick: handleShowOriginal,
    },
  ];
  return (
    <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <Dropdown
        menu={{
          items,
        }}
        open={open}
      >
        <Space
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faTools} />
          <DownOutlined />
        </Space>
      </Dropdown>
      {/** ************************* */}
      {/** BODY */}
    </div>
  );
}

export default MonitoringMapViewConfig;
