/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACE
import {
  MapViewDirections,
  MapViewInterface,
  MapViewPin,
  MapViewTypes,
} from "../../interfaces/MapView.interface";

interface MapViewState {
  openSelectMode: boolean;
  selectedItem: MapViewPin;
  mapViewItem: MapViewInterface | null;
  mapViewArray: MapViewInterface[];
  mapViewPinArray: MapViewPin[];
  refreshTable: boolean;
  showTable: boolean;
  formOptionMapView: string;
  openModalDelete: boolean;
  openForm: boolean;
  selectedOrganization: string;
  otsEnabled: boolean;
  floorsEnabled: boolean;
  namesEnabled: boolean;
  showOriginal?: boolean;
}

const initialState: MapViewState = {
  openSelectMode: false,
  selectedItem: {
    id: "",
    text: "",
    direction: MapViewDirections.DOWN,
    type: MapViewTypes.LIFT,
  },
  mapViewItem: null,
  mapViewArray: [],
  mapViewPinArray: [],
  refreshTable: false,
  showTable: false,
  formOptionMapView: "create",
  openModalDelete: false,
  openForm: false,
  selectedOrganization: "",
  otsEnabled: false,
  floorsEnabled: false,
  namesEnabled: false,
  showOriginal: false,
};

export const mapViewSlice = createSlice({
  name: "mapView",
  initialState,
  reducers: {
    updateOpenSelectMode: (state, action: PayloadAction<boolean>) => {
      state.openSelectMode = action.payload;
    },
    updateSelectedItem: (state, action: PayloadAction<MapViewPin>) => {
      state.selectedItem = action.payload;
    },
    updateMapViewArray: (state, action: PayloadAction<MapViewInterface[]>) => {
      state.mapViewArray = action.payload;
    },
    updateMapViewPinArray: (state, action: PayloadAction<MapViewPin[]>) => {
      state.mapViewPinArray = action.payload;
    },
    updateShowTable: (state, action: PayloadAction<boolean>) => {
      state.showTable = action.payload;
    },
    updateFormOptionMapView: (state, action: PayloadAction<string>) => {
      state.formOptionMapView = action.payload;
    },
    updateMapViewItem: (state, action: PayloadAction<MapViewInterface>) => {
      state.mapViewItem = action.payload;
    },
    updateRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.refreshTable = action.payload;
    },
    updateOpenModalDelete: (state, action: PayloadAction<boolean>) => {
      state.openModalDelete = action.payload;
    },
    updateOpenForm: (state, action: PayloadAction<boolean>) => {
      state.openForm = action.payload;
    },
    updateSelectedOrganization: (state, action: PayloadAction<string>) => {
      state.selectedOrganization = action.payload;
    },
    updateOtsEnabled: (state, action: PayloadAction<boolean>) => {
      state.otsEnabled = action.payload;
    },
    updateFloorsEnabled: (state, action: PayloadAction<boolean>) => {
      state.floorsEnabled = action.payload;
    },
    updateNamesEnabled: (state, action: PayloadAction<boolean>) => {
      state.namesEnabled = action.payload;
    },
    updateShowOriginal: (state, action: PayloadAction<boolean>) => {
      state.showOriginal = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateOpenSelectMode,
  updateSelectedItem,
  updateMapViewPinArray,
  updateFormOptionMapView,
  updateShowTable,
  updateMapViewItem,
  updateRefreshTable,
  updateOpenModalDelete,
  updateOpenForm,
  updateMapViewArray,
  updateSelectedOrganization,
  updateOtsEnabled,
  updateFloorsEnabled,
  updateNamesEnabled,
  updateShowOriginal,
} = mapViewSlice.actions;

export default mapViewSlice.reducer;
