import React from "react";
// NAVIGATION
import { useNavigate, useLocation } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Breadcrumb } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";

library.add(faUser);

function NotifcationSchedulesBreadcrumb() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const navigate = useNavigate();
	const location = useLocation();
	const idBank = new URLSearchParams(location.search).get("idBank");
	const idAlert = new URLSearchParams(location.search).get("idAlert");
	const idUser = new URLSearchParams(location.search).get("idUser");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const navigateToBanks = () => {
		navigate({
			pathname: "/notification-management/notifications-banks",
		});
	};

	const navigateToAlerts = () => {
		navigate({
			pathname: "/notification-management/notifications-alerts",
			search: idBank ? `?idBank=${idBank}` : "",
		});
	};

	const navigateToUsers = () => {
		let searchParams = "";
		if (idBank && !idAlert) {
			// eslint-disable-next-line no-template-curly-in-string
			searchParams = "?idBank=${idBank}";
		} else if (idBank && idAlert) {
			searchParams = `?idBank=${idBank}&idAlert=${idAlert}`;
		} else if (!idBank && idAlert) {
			searchParams = `?idAlert=${idAlert}`;
		}
		navigate({
			pathname: "/notification-management/notifications-users",
			search: searchParams,
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Breadcrumb className=''>
			<Breadcrumb.Item>{t("general.notificationManagement")}</Breadcrumb.Item>
			{idBank && idBank !== "" && (
				<Breadcrumb.Item
					className='generalStyles__breadcrumbItemInactive'
					onClick={navigateToBanks}
				>
					{t("general.banks")}
				</Breadcrumb.Item>
			)}
			{idAlert && idAlert !== "" && (
				<Breadcrumb.Item
					className='generalStyles__breadcrumbItemInactive'
					onClick={navigateToAlerts}
				>
					{t("general.alerts")}
				</Breadcrumb.Item>
			)}
			{idUser && idUser !== "" && (
				<Breadcrumb.Item
					className='generalStyles__breadcrumbItemInactive'
					onClick={navigateToUsers}
				>
					{t("general.users")}
				</Breadcrumb.Item>
			)}
			<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
				{t("general.schedules")}
			</Breadcrumb.Item>
		</Breadcrumb>
	);
}

export default NotifcationSchedulesBreadcrumb;
