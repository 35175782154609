/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Divider, Tag, Tooltip } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTES
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
// INTERFACES
import { MapViewPin3Interface } from "../../../interfaces/MapView.interface";
import { RootState } from "../../../app/store";
function MonitoringMapViewPin3({
	carName,
	nextStop,
	firstStop,
	secondStop,
	icon,
	serviceMode,
	direction,
	orientation,
	fault,
	liftType,
}: MapViewPin3Interface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [showContent, setShowContent] = useState<boolean>(false);
	const [boxSize, setBoxSize] = useState<"sm" | "md" | "lg">("sm");
	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { otsEnabled, floorsEnabled, namesEnabled } = useSelector(
		(state: any) => state.mapView
	);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleMouseOver = () => {
		setShowContent(true);
	};
	const handleMouseLeave = () => {
		setShowContent(false);
	};
	const getPinClass = () => {
		let pinClass = "mapViewStyles__box";
		pinClass = pinClass.concat(" mapViewStyles__box__", theme);
		if (showContent || otsEnabled || floorsEnabled || namesEnabled) {
			pinClass = pinClass.concat(
				" mapViewStyles__box__",
				boxSize,
				"__",
				orientation,
				"__",
				"expanded"
			);
		} else {
			pinClass = pinClass.concat(" mapViewStyles__box__minimized");
		}
		pinClass = pinClass.concat(
			" mapViewStyles__arrow__",
			direction,
			"__",
			theme
		);
		pinClass = pinClass.concat(
			" mapViewStyles__box__",
			boxSize,
			"__",
			orientation
		);
		if (fault) {
			pinClass = pinClass.concat(
				" mapViewStyles__box__error__",
				direction,
				"__",
				theme
			);
		}
		return pinClass;
	};
	const getAlignItemClass = () => {
		if (direction === "top") {
			return "flex-start";
		} else if (direction === "bottom") {
			return "flex-end";
		}
		return "center";
	};
	const getJustifyContentClass = () => {
		if (direction === "top") {
			return "center";
		} else if (direction === "bottom") {
			return "center";
		} else if (direction === "right") {
			return "flex-end";
		} else if (direction === "left") {
			return "flex-start";
		}
		return "center";
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		let items: any = [];
		if (orientation === "vertical") {
			items = [firstStop, carName, serviceMode].filter(
				(item) => item && item !== ""
			);
		} else {
			items = [firstStop, serviceMode].filter((item) => item && item !== "");
		}
		let totalItems = 0;
		if (orientation === "vertical") {
			totalItems = items.length;
		} else {
			totalItems = items.length + 1;
		}
		if (totalItems === 1) {
			setBoxSize("sm");
		} else if (totalItems === 2) {
			setBoxSize("md");
		} else if (totalItems === 3) {
			setBoxSize("lg");
		}
	}, [firstStop, carName, serviceMode, showContent]);
	useEffect(() => {
		if (showContent) return;
		let totalItems = 0;
		if (otsEnabled && serviceMode && serviceMode !== "") totalItems++;
		if (floorsEnabled && firstStop && firstStop !== "") totalItems++;
		if (namesEnabled && carName && carName !== "") totalItems++;
		if (totalItems === 1) {
			setBoxSize("sm");
		} else if (totalItems === 2) {
			setBoxSize("md");
		} else if (totalItems === 3) {
			setBoxSize("lg");
		}
	}, [
		otsEnabled,
		floorsEnabled,
		namesEnabled,
		showContent,
		serviceMode,
		firstStop,
		carName,
	]);
	// ************************************************ */
	// COMPONENT ************************************** */
	const canShowServiceMode =
		(otsEnabled || showContent) && serviceMode && serviceMode !== "";
	const canShowName =
		(namesEnabled || showContent) && carName && carName !== "";
	const canShowFloors =
		(floorsEnabled || showContent) && firstStop && firstStop !== "";
	return (
		<div>
			{/** ************************************************************** */}
			{/** VERTICAL */}
			{orientation === "vertical" && (
				<div
					className={`mapViewStyles__box__${boxSize}__vertical__expanded`}
					style={{
						justifyContent: getJustifyContentClass(),
						alignItems: getAlignItemClass(),
						display: "flex",
						borderRadius: "8px",
					}}
				>
					<div
						className={getPinClass()}
						onMouseOver={handleMouseOver}
						onMouseLeave={handleMouseLeave}
					>
						{/** CAR NAME */}
						{canShowName && (
							<div
								style={{
									height: "16px",
									fontSize: "10px",
									width: "90%",
									marginLeft: "5%",
									marginRight: "5%",
									paddingTop: "3px",
								}}
								className='generalStyles__textEllipsis'
							>
								{carName.length >= 8 ? (
									<Tooltip placement='top' title={carName}>
										<p>{carName}</p>
									</Tooltip>
								) : (
									<p>{carName}</p>
								)}
							</div>
						)}
						{/** ICON */}
						{icon && (
							<div
								style={{
									width: "100%",
								}}
							>
								<IconDefinitionIcons
									isMap
									expanded={showContent}
									liftType={liftType.toUpperCase()}
									icon={icon} // last short message
									iconSize={16}
								/>
							</div>
						)}
						{/** NEXT STOP */}
						{canShowFloors && (
							<Tooltip placement='bottom' title={firstStop + ">" + secondStop}>
								<div
									className='generalStyles__flex'
									style={{
										fontSize: "10px",
										width: "100%",
										height: "16px",
									}}
								>
									<div
										className=''
										style={{ width: "33%", textAlign: "center" }}
									>
										<p>{firstStop.substring(0, 2)}</p>{" "}
									</div>
									<div
										className=''
										style={{ width: "33%", textAlign: "center" }}
									>
										<FontAwesomeIcon icon={faCaretRight} />
									</div>
									<div
										className=''
										style={{ width: "33%", textAlign: "center" }}
									>
										<p>{secondStop.substring(0, 2)}</p>
									</div>
								</div>
							</Tooltip>
						)}
						{/** SERVICE MODE */}
						{canShowServiceMode && (
							<div
								style={{
									height: "16px",
									width: "100%",
									fontSize: "10px",
									color: "cyan",
								}}
							>
								{serviceMode.toUpperCase()}
							</div>
						)}
					</div>
				</div>
			)}
			{/** ************************************************************** */}
			{/** HORIZONTAL */}
			{orientation === "horizontal" && (
				<div
					className={`mapViewStyles__box__${boxSize}__horizontal__expanded`}
					style={{
						justifyContent: getJustifyContentClass(),
						alignItems: getAlignItemClass(),
						display: "flex",
						borderRadius: "8px",
					}}
				>
					{" "}
					<div
						className={getPinClass()}
						onMouseOver={handleMouseOver}
						onMouseLeave={handleMouseLeave}
					>
						{/** CAR NAME */}
						{canShowName && (
							<div
								style={{
									height: "16px",
									fontSize: "10px",
									width: "90%",
									marginLeft: "5%",
									marginRight: "5%",
									paddingTop: "3px",
								}}
								className='generalStyles__textEllipsis'
							>
								{carName.length >= 8 ? (
									<Tooltip placement='top' title={carName}>
										<p>{carName}</p>
									</Tooltip>
								) : (
									<p>{carName || "--"}</p>
								)}
							</div>
						)}
						<div className='generalStyles__flex'>
							{/** NEXT STOP */}
							{canShowFloors && (
								<Tooltip
									placement='bottom'
									title={firstStop + ">" + secondStop}
								>
									<div
										className='generalStyles__flex'
										style={{
											fontSize: "10px",
											width: "40px",
											height: "32px",
											paddingTop: "4px",
										}}
									>
										<div
											className=''
											style={{ width: "33%", textAlign: "center" }}
										>
											<p>{firstStop.substring(0, 2)}</p>{" "}
										</div>
										<div
											className=''
											style={{ width: "33%", textAlign: "center" }}
										>
											<FontAwesomeIcon icon={faCaretRight} />
										</div>
										<div
											className=''
											style={{ width: "33%", textAlign: "center" }}
										>
											<p>{secondStop.substring(0, 2)}</p>
										</div>
									</div>
								</Tooltip>
							)}
							{/** ICON */}
							{icon && (
								<div
									style={{
										width: "40px",
									}}
								>
									<IconDefinitionIcons
										isMap
										expanded={showContent}
										liftType={liftType.toUpperCase()}
										icon={icon} // last short message
										iconSize={16}
									/>
								</div>
							)}
							{/** SERVICE MODE */}
							{canShowServiceMode && (
								<div
									style={{
										height: "32px",
										width: "40px",
										fontSize: "10px",
										color: "cyan",
										marginTop: "4px",
									}}
								>
									{serviceMode.toUpperCase()}
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
export default MonitoringMapViewPin3;
