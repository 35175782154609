import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Select, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetServersQuery } from "../../../redux/servers/serverAPI";
import {
	updateFilters,
	updateServers,
	updateIdServerPositionCero,
} from "../../../redux/reports/reportsSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { ServerInterface } from "../../../interfaces/EdgeManagement.interface";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";
import { RootState } from "../../../app/store";

function ReportsFilterGetServers() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, blockFilters, servers } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetServers, { data, isError }] = useLazyGetServersQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initialData = async () => {
		const token = await GETJwtToken();
		triggerGetServers({
			page: 1,
			limit: 9999,
			token,
		});
	};

	const onChangeServer = (newValue: string) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		const index = servers.findIndex((object: any) => object.id === newValue);
		if (servers[index]) {
			copy.serverId = servers[index].id;
			copy.serverName = servers[index].name;
			if (servers[index].org) {
				copy.organizationId = servers[index].org.id;
				copy.organizationName = servers[index].org.name;
			}
		}
		dispatch(updateFilters(copy));
	};

	const callFirstServer = () => {
		if (servers && servers[0] && servers[0].id && filters.serverId === "") {
			onChangeServer(servers[0].id);
		}
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (servers.length === 0) {
			initialData();
		}
	}, []);

	useEffect(() => {
		if (data && data.data && data.data.length > 0) {
			dispatch(updateServers(data.data));
			dispatch(updateIdServerPositionCero(data.data[0].id));
		}
	}, [data]);

	useEffect(() => {
		if (isError) {
			dispatch(updateServers([]));
		}
	}, [isError]);

	useEffect(() => {
		callFirstServer();
	}, [servers]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__width100'>
			<Tooltip title={servers.length === 0 ? t("report.noServers") : ""}>
				<Select
					className='generalStyles__width100'
					disabled={servers.length === 0 || blockFilters}
					showSearch
					optionFilterProp='children'
					size='small'
					placeholder={t("report.selectServer")}
					value={filters.serverId === "" ? null : filters.serverId}
					filterOption={(input: string, option: any) =>
						option.children.toLowerCase().includes(input.toLowerCase())
					}
					onChange={onChangeServer}
				>
					{servers.map((server: ServerInterface) => (
						<Option key={server.id} value={server.id}>
							{server.name}
						</Option>
					))}
				</Select>
			</Tooltip>
		</div>
	);
}

export default ReportsFilterGetServers;
