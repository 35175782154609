/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NotificationState {
	notification: any;
	levelBreadcrumb: number;
	alert: any;
	openDrawerAssignUser: boolean;
	openDrawerManageNotifications: boolean;
	openDrawerManageSchedules: boolean;
}

const initialState: NotificationState = {
	notification: {},
	levelBreadcrumb: 0,
	alert: {},
	openDrawerAssignUser: false,
	openDrawerManageNotifications: false,
	openDrawerManageSchedules: false,
};

export const notificationSlice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		updateNotification: (state, action: PayloadAction<any>) => {
			state.notification = action.payload;
		},
		updateLevelbreadcrumb: (state, action: PayloadAction<number>) => {
			state.levelBreadcrumb = action.payload;
		},
		updateAlert: (state, action: PayloadAction<any>) => {
			state.alert = action.payload;
		},
		updateOpenDrawerAssignUser: (state, action: PayloadAction<boolean>) => {
			state.openDrawerAssignUser = action.payload;
		},
		updateOpenDrawerManageNotifications: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.openDrawerManageNotifications = action.payload;
		},
		updateOpenDrawerManageSchedules: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.openDrawerManageSchedules = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateNotification,
	updateLevelbreadcrumb,
	updateAlert,
	updateOpenDrawerAssignUser,
	updateOpenDrawerManageNotifications,
	updateOpenDrawerManageSchedules,
} = notificationSlice.actions;

export default notificationSlice.reducer;
