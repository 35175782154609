import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
  Alert,
  Button,
  Table,
  Empty,
  Tag,
  Input,
  Tooltip,
  Upload,
  UploadFile,
  Switch,
} from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSave,
  faSearch,
  faX,
} from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  updateMapViewItem,
  updateShowTable,
  updateFormOptionMapView,
  updateMapViewArray,
} from "../../../redux/mapView/mapViewSlice";
import {
  useLazyGetMapViewQuery,
  usePostMapViewMutation,
  usePutMapViewMutation,
} from "../../../redux/mapView/mapViewAPI";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
import MapViewTableActions from "./MapViewTableActions";
import MapViewHeader from "./MapViewHeader";
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// INTERFACES
import { RootState } from "../../../app/store";
import { MapViewInterface } from "../../../interfaces/MapView.interface";

function MapViewTable() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const { Column, ColumnGroup } = Table;
  const BLANK_MAP_VIEW = {
    id: "",
    name: "",
    start: false,
    file: "",
    setup: "{}",
    org_id: "",
  };

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [mapView, setMapView] = useState<MapViewInterface>(BLANK_MAP_VIEW);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [totalRows, setTotalRows] = useState<number>(0);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  const [
    triggerGetMapView,
    {
      data: dataGetMapView,
      isLoading: isLoadingGetMapView,
      isFetching: isFetchingGetMapView,
      isError: isErrorGetMapView,
      error: errorGetMapView,
    },
  ] = useLazyGetMapViewQuery();

  const [
    triggerPostMapView,
    {
      isSuccess: isSuccessPostMapView,
      isLoading: isLoadingPostMapView,
      isError: isErrorPostMapView,
      error: errorPostMapView,
      reset: resetPostMapView,
    },
  ] = usePostMapViewMutation();

  const [
    triggerPutMapView,
    {
      isSuccess: isSuccessPutMapView,
      isLoading: isLoadingPutMapView,
      isError: isErrorPutMapView,
      error: errorPutMapView,
      reset: resetPutMapView,
    },
  ] = usePutMapViewMutation();

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { theme } = useSelector((state: RootState) => state.home);
  const { permissions } = useSelector((state: any) => state.user);
  const {
    refreshTable,
    showTable,
    formOptionMapView,
    selectedOrganization,
    mapViewItem: selectedMapView,
  } = useSelector((state: RootState) => state.mapView);

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickShowForm = () => {
    setIsLoading(true);
    dispatch(updateShowTable(true));
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const getInitialData = async () => {
    if (!selectedOrganization) {
      return;
    }
    const token = await GETJwtToken();
    const BODY: any = {
      org_id: selectedOrganization,
    };
    triggerGetMapView({
      token,
      body: BODY,
    });
  };

  const onClickRefreshTable = async () => {
    getInitialData();
  };

  const onChangeFormValue = (newValue: string, variableName: string) => {
    const copy: MapViewInterface = structuredClone(mapView);
    copy[variableName] = newValue;
    setMapView(copy);
  };

  const resetForm = () => {
    resetPostMapView();
    resetPutMapView();
    setMapView(BLANK_MAP_VIEW);
    setErrorMessage({});
    dispatch(updateFormOptionMapView("create"));
    dispatch(updateMapViewItem(BLANK_MAP_VIEW));
    setFileList([]);
  };

  const onClickSave = async () => {
    if (!selectedOrganization || !mapView.name) {
      return;
    }
    const token = await GETJwtToken();
    const BODY: any = {
      name: mapView.name,
      start: mapView.start,
      setup: mapView.setup ? JSON.stringify(mapView.setup) : "{}",
      orgId: selectedOrganization,
      file: fileList[0] ? fileList[0] : undefined,
    };
    if (formOptionMapView === "create") {
      await triggerPostMapView({
        token,
        body: BODY,
      });
    } else {
      await triggerPutMapView({
        token,
        body: {
          ...BODY,
          id: selectedMapView?.id,
        },
      });
    }
    resetForm();
    getInitialData();
  };

  const checkFormIsValid = () => {
    let formIsValid = true;
    if (mapView.cmd === "" || mapView.label === "" || mapView.tooltip === "") {
      formIsValid = false;
    }
    return formIsValid;
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (showTable) {
      getInitialData();
    }
  }, [showTable]);

  useEffect(() => {
    if (refreshTable) {
      onClickRefreshTable();
    }
  }, [refreshTable]);

  useEffect(() => {
    if (dataGetMapView?.data) {
      setData(dataGetMapView.data);
      dispatch(updateMapViewArray(dataGetMapView.data));
      setTotalRows(dataGetMapView.total);
    }
  }, [dataGetMapView]);

  useEffect(() => {
    if (errorGetMapView) {
      setErrorMessage(errorGetMapView);
      setData([]);
    }
  }, [errorGetMapView]);

  useEffect(() => {
    resetForm();
  }, [
    isSuccessPostMapView,
    isSuccessPutMapView,
    isErrorPostMapView,
    isErrorPutMapView,
  ]);

  useEffect(() => {
    if (formOptionMapView === "create") {
      setMapView(BLANK_MAP_VIEW);
    }
  }, [formOptionMapView]);

  useEffect(() => {
    setMapView(selectedMapView ?? BLANK_MAP_VIEW);
  }, [selectedMapView]);

  // trigger get data on organization change
  useEffect(() => {
    getInitialData();
  }, [selectedOrganization]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {/** ----------------------------------------------------- */}
      {/** SHOW ALERT */}
      {!showTable && (
        <div className="mb-4">
          <Alert
            message={t("general.warning")}
            description={t("mapView.warningDescription")}
            type="warning"
            showIcon
            closable
          />
          <Button
            className="buttonStyle__2 configuration__editRootPermisionsButton"
            onClick={onClickShowForm}
          >
            {t("general.accept")}
          </Button>
        </div>
      )}

      {/** ----------------------------------------------------- */}
      {/** ACTION FORM */}
      {showTable && (
        <div>
          {validatePermission("permission-not-defined", permissions) ? (
            <>
              <MapViewHeader />
              {selectedOrganization ? (
                <Table
                  locale={{
                    emptyText: (
                      <TableNoDataBox
                        isError={isErrorGetMapView}
                        errorMessage={
                          errorMessage &&
                          errorMessage.data &&
                          errorMessage.data.message
                            ? errorMessage.data.message
                            : "Error"
                        }
                        noItemsFound="No mapView found"
                      />
                    ),
                  }}
                  rowClassName={(record, index) =>
                    getTableRowClass(index, theme)
                  }
                  dataSource={data}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50", "100"],
                  }}
                  size="small"
                  className="mt-3"
                  loading={
                    isFetchingGetMapView ||
                    isLoading ||
                    isLoadingGetMapView ||
                    isLoadingPostMapView ||
                    isLoadingPutMapView
                  }
                  footer={() => (
                    <div className="generalStyles__flexEnd">
                      <Tag>{totalRows} Rows</Tag>
                    </div>
                  )}
                >
                  {/** NAME */}
                  <ColumnGroup title="Name">
                    <Column
                      title={
                        <Input
                          size="small"
                          placeholder="Name"
                          value={
                            formOptionMapView === "create" ? mapView.name : ""
                          }
                          disabled={formOptionMapView === "edit"}
                          onChange={(
                            event: React.FormEvent<HTMLInputElement>
                          ) => {
                            onChangeFormValue(
                              event.currentTarget.value,
                              "name"
                            );
                          }}
                        />
                      }
                      dataIndex="name"
                      key="name"
                      className=""
                      render={(text, record: MapViewInterface) => (
                        <div>
                          {formOptionMapView === "edit" &&
                          record.id === mapView.id ? (
                            <Input
                              size="small"
                              placeholder="Name"
                              value={mapView.name}
                              onChange={(
                                event: React.FormEvent<HTMLInputElement>
                              ) => {
                                onChangeFormValue(
                                  event.currentTarget.value,
                                  "name"
                                );
                              }}
                            />
                          ) : (
                            <span>{text || "--"}</span>
                          )}
                        </div>
                      )}
                    />
                  </ColumnGroup>
                  {/** STARTING POINT */}
                  <ColumnGroup title="Start">
                    <Column
                      title={
                        <Switch
                          size="small"
                          checked={mapView.start}
                          onChange={() =>
                            setMapView({ ...mapView, start: !mapView.start })
                          }
                        />
                      }
                      dataIndex="start"
                      key="start"
                      className=""
                      render={(text, record: MapViewInterface) => (
                        <div>
                          {formOptionMapView === "edit" &&
                          record.id === mapView.id ? (
                            <Switch
                              size="small"
                              checked={mapView.start}
                              onChange={() =>
                                setMapView({
                                  ...mapView,
                                  start: !mapView.start,
                                })
                              }
                            />
                          ) : (
                            <span>
                              {text ? (
                                <FontAwesomeIcon icon={faCheck} color="green" />
                              ) : (
                                <FontAwesomeIcon icon={faX} color="red" />
                              )}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </ColumnGroup>
                  {/** IMAGE */}
                  <ColumnGroup title="Image">
                    <Column
                      title={
                        <Upload
                          accept="image/svg+xml"
                          beforeUpload={(file: any) => {
                            const isSvg = file.type === "image/svg+xml";
                            if (!isSvg) {
                              return false;
                            }
                            setFileList([file]);
                            return false; // Prevent automatic upload
                          }}
                          maxCount={1}
                          multiple={false}
                          fileList={fileList}
                          onRemove={() => {
                            setFileList([]);
                          }}
                        >
                          <Button>Select Image</Button>
                        </Upload>
                      }
                      dataIndex="file"
                      key="file"
                      className=""
                      render={(text, record: MapViewInterface) => (
                        <div>
                          {formOptionMapView === "edit" &&
                          record.id === mapView.id ? (
                            <Upload
                              accept="image/svg+xml"
                              beforeUpload={(file: any) => {
                                const isSvg = file.type === "image/svg+xml";
                                if (!isSvg) {
                                  return false;
                                }
                                setFileList([file]);
                                return false; // Prevent automatic upload
                              }}
                              maxCount={1}
                              multiple={false}
                              fileList={fileList}
                              onRemove={() => {
                                setFileList([]);
                              }}
                            >
                              <Button>Select Image</Button>
                            </Upload>
                          ) : (
                            <a
                              href={record.s3_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FontAwesomeIcon icon={faSearch} /> See preview
                            </a>
                          )}
                        </div>
                      )}
                    />
                  </ColumnGroup>
                  {/** ACTIONS */}
                  <ColumnGroup
                    align="center"
                    title={t("edgeManagement.banks.actions")}
                  >
                    <Column
                      align="center"
                      title={
                        <div className="generalStyles__flexCenter">
                          <div className="">
                            <Tooltip title={t("general.save")}>
                              <Button
                                className={
                                  formOptionMapView === "create" &&
                                  checkFormIsValid()
                                    ? "buttonStyle__10"
                                    : ""
                                }
                                disabled={
                                  !checkFormIsValid() ||
                                  formOptionMapView === "edit"
                                }
                                onClick={onClickSave}
                                icon={<FontAwesomeIcon icon={faSave} />}
                              />
                            </Tooltip>
                          </div>
                          <div className="generalStyles__mlFix">
                            <Tooltip title={t("general.clear")}>
                              <Button
                                className={
                                  formOptionMapView === "create"
                                    ? "buttonStyle__14"
                                    : ""
                                }
                                onClick={resetForm}
                                disabled={formOptionMapView === "edit"}
                                icon={<FontAwesomeIcon icon={faX} />}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      }
                      key="mapView"
                      render={(_: any, record: MapViewInterface) => (
                        <div>
                          {formOptionMapView === "edit" &&
                          record.id === mapView.id ? (
                            <div className="generalStyles__flexCenter">
                              <div className="">
                                <Tooltip title={t("general.save")}>
                                  <Button
                                    className="buttonStyle__10"
                                    disabled={!checkFormIsValid()}
                                    onClick={onClickSave}
                                    icon={<FontAwesomeIcon icon={faSave} />}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          ) : (
                            <MapViewTableActions selectedItem={record} />
                          )}
                        </div>
                      )}
                    />
                  </ColumnGroup>
                </Table>
              ) : (
                <>
                  <div className="generalStyles__noAccessToListTable">
                    <Empty
                      description={t("mapView.warningNoOrganization")}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="generalStyles__noAccessToListTable">
                <Empty
                  description={t(
                    "organizationManagement.listNotAvailableOrganizations"
                  )}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </div>
            </>
          )}
        </div>
      )}
      {/** ---------------------------------------------------- */}
      {/** GLOBAL ALERT */}
      <GlobalAlert2
        isError={isErrorPostMapView}
        isSuccess={isSuccessPostMapView}
        requestType="POST"
        error={errorPostMapView}
        name="MapView"
      />
      <GlobalAlert2
        isError={isErrorPutMapView}
        isSuccess={isSuccessPutMapView}
        requestType="PUT"
        error={errorPutMapView}
        name="MapView"
      />
    </div>
  );
}

export default MapViewTable;
