import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { DatePicker } from "antd";
// MOMENT DATE FORMAT
import moment from "moment";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterDateDay() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, blockFilters } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChange = (date: any, dateString: string) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.startDate = moment(dateString).tz(filters.timezoneCode).toISOString();
		copy.endDate = moment(dateString).tz(filters.timezoneCode).toISOString();
		dispatch(updateFilters(copy));
	};

	const generateDefaultTodayDate = () => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.startDate = moment()
			.startOf("day")
			.tz(filters.timezoneCode || moment.tz.guess())
			.toISOString();
		copy.endDate = moment()
			.endOf("day")
			.tz(filters.timezoneCode || moment.tz.guess())
			.toISOString();
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		generateDefaultTodayDate();
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<DatePicker
			className='generalStyles__width100'
			style={{ height: "24px" }}
			onChange={onChange}
			value={moment(filters.startDate)}
			allowClear={false}
			disabled={blockFilters}
		/>
	);
}

export default ReportsFilterDateDay;
