import React from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Checkbox } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { faUser } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerManageNotifications } from "../../../redux/notifications/notificationSlice";
// COMPONENTS
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
import DrawerCloseSaveButton from "../../components/drawer/DrawerCloseSaveButton";
import DrawerRowItem from "../../components/drawer/DrawerRowItem";
// INTERFACES
import { RootState } from "../../../app/store";

function NotificationUserDrawerManageNotifications() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerManageNotifications } = useSelector(
		(state: RootState) => state.notification
	);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerManageNotifications(false));
	};

	const onClickSave = () => {
		dispatch(updateOpenDrawerManageNotifications(false));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='550'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerManageNotifications}
		>
			<div>
				<DrawerRowDivider icon={faUser} title={t("general.user")} />
			</div>
			<div className={`drawer__box__${theme}`}>
				<DrawerRowItem
					title='ID'
					value='a4a8s5s78s-ad5fadsf-823jkf8923-adsfasd'
				/>
				<DrawerRowItem title={t("general.message")} value='Fault 1' />
				<DrawerRowItem title={t("general.bank")} value='West Loch ES1' />
				<DrawerRowItem title={t("general.organization")} value='West Loch' />
			</div>
			<br />
			<div>
				<DrawerRowDivider icon={faUser} title={t("general.assignUser")} />
			</div>
			<div className={`drawer__box__${theme}`}>
				{[
					{
						id: 1,
						name: "Email",
					},
					{
						id: 2,
						name: "SMS",
					},
					{
						id: 3,
						name: "WhatsApp",
					},
				].map((user: any) => (
					<div className='generalStyles__flex mt-2' key={user.id}>
						<div className='generalStyles__width50px'>
							<Checkbox />
						</div>
						<div>{user.name}</div>
					</div>
				))}
			</div>
			{/** ---------------------------------------------------- */}
			{/** COMPONENT CLOSE SAVE BUTTON */}
			<DrawerCloseSaveButton
				onClickClose={onClickClose}
				onClickSave={onClickSave}
				disableSaveButton={false}
			/>
			<br />
		</Drawer>
	);
}

export default NotificationUserDrawerManageNotifications;
