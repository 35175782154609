import React from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import {
	faBan,
	faCalendar,
	faChartBar,
	faFilter,
	faGlobe,
	faSort,
	faTools,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateOpenDrawerFilters } from "../../../redux/reports/reportsSlice";
// COMPONENTS
import ReportsDrawerOffsetTypeFilters from "./ReportsDrawerOffsetTypeFilters";
import ReportsDrawerTimestampFilters from "./ReportsDrawerTimestampFilters";
import ReportsDrawerSelectColumnsFilters from "../ReportsDrawerSelectColumnsFilters";
import ReportsDrawerAdvanceFilters from "./ReportsDrawerAdvanceFilters";
import ReportsDrawerExclusionsFilters from "./ReportsDrawerExclusionsFilters";
import ReportsDrawerSortFilters from "./ReportsDrawerSortFilters";
import ReportsDrawerChartsFilters from "./ReportsDrawerChartsFilters";
import ReportsDrawerDateAndTimeFilters from "./ReportsDrawerDateAndTimeFilters";
// COMPONENTS
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
// INTERFACES
import { RootState } from "../../../app/store";
import {} from "../../../interfaces/Reports.interface";

function ReportsDrawerFilters() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerFilters, filters } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerFilters(false));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='600'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerFilters}
		>
			{/** ************************************************* */}
			{/** ADVANCE FILTERS */}
			<DrawerRowDivider icon={faFilter} title={t("general.advanceFilters")} />
			<ReportsDrawerAdvanceFilters />
			{/** ************************************************* */}
			{/** DATE AND TIME FILTERS */}
			{filters.displayDate && (
				<>
					<br />
					<DrawerRowDivider
						icon={faCalendar}
						title={t("general.dateAndTime")}
					/>
					<ReportsDrawerDateAndTimeFilters />
				</>
			)}
			{/** ************************************************* */}
			{/** SORT/TABLE */}
			{filters.displayAdvanceFiltersTable && (
				<>
					<br />
					<DrawerRowDivider icon={faSort} title={t("general.filters")} />
					<ReportsDrawerSortFilters />
				</>
			)}
			{/** ************************************************* */}
			{/* TIMEZONE FILTERS */}
			<br />
			<DrawerRowDivider icon={faGlobe} title={t("general.timezone")} />
			<ReportsDrawerTimestampFilters />
			{/** ************************************************* */}
			{/* CHARTS */}
			{filters.displayAdvanceFilterCharts && (
				<>
					<br />
					<DrawerRowDivider icon={faChartBar} title={t("general.charts")} />
					<ReportsDrawerChartsFilters />
				</>
			)}
			{/** ************************************************* */}
			{/** EXLUSIONS */}
			{filters.displayAdvanceFiltersExclusions && (
				<>
					<br />
					<DrawerRowDivider icon={faBan} title={t("general.exclusions")} />
					<ReportsDrawerExclusionsFilters />
				</>
			)}
			{/** ************************************************************************ */}
			{/** OFFSETS FILTERS */}
			{filters.displayAdvanceFiltersOffsets && (
				<>
					<br />
					<DrawerRowDivider icon={faTools} title='Offsets' />
					<ReportsDrawerOffsetTypeFilters />
				</>
			)}
			{/** ************************************************************************ */}
			{/** TABLE COLUMNS FILTERS */}
			<br />
			<ReportsDrawerSelectColumnsFilters />
			{/** ************************************************************************ */}
			{/** CLOSE AND SUBMIT BUTTONS */}
			<div className=' mt-4 generalStyles__width100'>
				<Button
					onClick={onClickClose}
					className='buttonStyle__11 generalStyles__width100'
				>
					{t("general.close")}
				</Button>
			</div>
		</Drawer>
	);
}

export default ReportsDrawerFilters;
