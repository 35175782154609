import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Button, Input } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";

function ModalDeleteItemContainer({ name, onClickDelete, onClickClose }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [confirmItemName, setConfirmItemName] = useState<string>("");
	const [disableButton, setDisableButton] = useState<boolean>(true);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openModalDeleteAdapter } = useSelector((state: any) => state.adapter);
	const { openModalDeleteUser } = useSelector((state: any) => state.user);
	const { openModalDelete } = useSelector((state: any) => state.action);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onKeyDownDeleteItem = async (event: any) => {
		if (event.key === "Enter" && !disableButton) {
			onClickDelete();
			setConfirmItemName("");
			setDisableButton(true);
		}
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (
			confirmItemName &&
			confirmItemName.toLowerCase() === name.toLowerCase()
		) {
			setDisableButton(false);
		} else {
			setDisableButton(true);
		}
	}, [confirmItemName]);

	useEffect(() => {
		if (!openModalDeleteAdapter || openModalDeleteUser || openModalDelete) {
			setDisableButton(true);
			setConfirmItemName("");
		}
	}, [openModalDeleteAdapter, openModalDeleteUser, openModalDelete]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='text-center'>
				<FontAwesomeIcon
					icon={faTriangleExclamation}
					className='generalStyles__warning generalStyles__deleteItemIcon'
				/>
				<p className='mt-3 drawer__text'>
					{t("general.deleteDescription1")}{" "}
					<b className='generalStyles__warning'>
						{t("general.delete").toUpperCase()}{" "}
					</b>
					{t("general.deleteDescription2")}{" "}
					<b className='generalStyles__warning'>{name}</b>
				</p>
				<p className='drawer__text' style={{ marginTop: "-10px" }}>
					{t("general.deleteDescription3")}
				</p>
				<Input
					placeholder={t("general.name")}
					onChange={(e) => {
						setConfirmItemName(e.target.value);
					}}
					value={confirmItemName}
					onKeyDown={onKeyDownDeleteItem}
					status={`${!disableButton ? "warning" : "warning"}`}
					autoFocus
				/>
			</div>
			<div className='generalStyles__flexEnd mt-4'>
				<div>
					<Button
						onClick={() => {
							onClickDelete();
							setConfirmItemName("");
							setDisableButton(true);
						}}
						disabled={disableButton}
						className={`${!disableButton ? "buttonStyle__2" : ""}`}
					>
						{t("general.delete")}
					</Button>
					<Button
						onClick={() => {
							onClickClose();
							setConfirmItemName("");
							setDisableButton(true);
						}}
						className='buttonStyle__4 generalStyles__mlFix'
					>
						{t("general.close")}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default ModalDeleteItemContainer;
